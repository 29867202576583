//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReportApprovalDetailEdm } from './reportapprovaldetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ReportApprovalDetailEdmComplexConfig = {
  name: 'ReportApprovalDetailEdm',
  fields: {
    transferGuid: {type: 'Edm.Guid', nullable: false},
    completedOn: {type: 'Edm.DateTimeOffset'},
    periodType: {type: 'Edm.Int16'},
    weekNo: {type: 'Edm.Byte'},
    weekYear: {type: 'Edm.Int16'},
    monthNo: {type: 'Edm.Int16'},
    monthYear: {type: 'Edm.Int16'},
    uploadStatus: {type: 'Edm.Int16', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    agencyName: {type: 'Edm.String'},
    miReportTypeGuid: {type: 'Edm.Guid', nullable: false},
    reportName: {type: 'Edm.String'},
    requiresApproval: {type: 'Edm.Boolean', nullable: false},
    status: {type: 'Edm.Int16'},
    hasPdfFile: {type: 'Edm.Boolean'},
    hasXlFile: {type: 'Edm.Boolean'},
    rowNum: {type: 'Edm.Int64'}
  }
} as StructuredTypeConfig<ReportApprovalDetailEdm>;
//#endregion