import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskPermissionEdm } from './DataDomain/EFEntities/wftaskpermissionedm.entity';
import { PermissionEdm } from './DataDomain/EFEntities/Admin/permissionedm.entity';
import { UserGroupPermissionEdm } from './DataDomain/EFEntities/Admin/usergrouppermissionedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class PermissionsService extends ODataEntitySetService<PermissionEdm> {
  constructor(client: ODataClient) {
    super(client, 'Permissions', 'OptiaApi.DataDomain.EFEntities.Admin.PermissionEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<PermissionEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<PermissionEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<PermissionEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<PermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public userGroupPermissions(key: EntityKey<PermissionEdm>): ODataNavigationPropertyResource<UserGroupPermissionEdm> { 
    return this.entity(key).navigationProperty<UserGroupPermissionEdm>('userGroupPermissions'); 
  }
  public fetchUserGroupPermissions(key: EntityKey<PermissionEdm>, options?: ODataQueryArgumentsOptions<UserGroupPermissionEdm>) {
    return this.fetchNavigationProperty<UserGroupPermissionEdm>(
      this.userGroupPermissions(key), 
      'entities', options) as Observable<ODataEntities<UserGroupPermissionEdm>>;
  }
  public addUserGroupPermissionEdmToUserGroupPermissions(key: EntityKey<PermissionEdm>, target: ODataEntityResource<ODataEntities<UserGroupPermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.userGroupPermissions(key).reference()
      .add(target);
  }
  public removeUserGroupPermissionEdmFromUserGroupPermissions(key: EntityKey<PermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<UserGroupPermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.userGroupPermissions(key).reference()
      .remove(target);
  }
  public wfTaskPermissions(key: EntityKey<PermissionEdm>): ODataNavigationPropertyResource<WfTaskPermissionEdm> { 
    return this.entity(key).navigationProperty<WfTaskPermissionEdm>('wfTaskPermissions'); 
  }
  public fetchWfTaskPermissions(key: EntityKey<PermissionEdm>, options?: ODataQueryArgumentsOptions<WfTaskPermissionEdm>) {
    return this.fetchNavigationProperty<WfTaskPermissionEdm>(
      this.wfTaskPermissions(key), 
      'entities', options) as Observable<ODataEntities<WfTaskPermissionEdm>>;
  }
  public addWfTaskPermissionEdmToWfTaskPermissions(key: EntityKey<PermissionEdm>, target: ODataEntityResource<ODataEntities<WfTaskPermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTaskPermissions(key).reference()
      .add(target);
  }
  public removeWfTaskPermissionEdmFromWfTaskPermissions(key: EntityKey<PermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WfTaskPermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTaskPermissions(key).reference()
      .remove(target);
  }
  //#endregion
}
