import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AccountBankDetailEdm } from './DataDomain/EFEntities/accountbankdetailedm.entity';
import { AccountPersonEdm } from './DataDomain/EFEntities/accountpersonedm.entity';
import { ContractEdm } from './DataDomain/EFEntities/contractedm.entity';
import { CreditRequestEdm } from './DataDomain/EFEntities/Customer/creditrequestedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class AccountsService extends ODataEntitySetService<AccountEdm> {
  constructor(client: ODataClient) {
    super(client, 'Accounts', 'OptiaApi.DataDomain.EFEntities.AccountEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public accountBankDetails(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<AccountBankDetailEdm> { 
    return this.entity(key).navigationProperty<AccountBankDetailEdm>('accountBankDetails'); 
  }
  public fetchAccountBankDetails(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<AccountBankDetailEdm>) {
    return this.fetchNavigationProperty<AccountBankDetailEdm>(
      this.accountBankDetails(key), 
      'entities', options) as Observable<ODataEntities<AccountBankDetailEdm>>;
  }
  public addAccountBankDetailEdmToAccountBankDetails(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntities<AccountBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.accountBankDetails(key).reference()
      .add(target);
  }
  public removeAccountBankDetailEdmFromAccountBankDetails(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.accountBankDetails(key).reference()
      .remove(target);
  }
  public accountPersons(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<AccountPersonEdm> { 
    return this.entity(key).navigationProperty<AccountPersonEdm>('accountPersons'); 
  }
  public fetchAccountPersons(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<AccountPersonEdm>) {
    return this.fetchNavigationProperty<AccountPersonEdm>(
      this.accountPersons(key), 
      'entities', options) as Observable<ODataEntities<AccountPersonEdm>>;
  }
  public addAccountPersonEdmToAccountPersons(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntities<AccountPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.accountPersons(key).reference()
      .add(target);
  }
  public removeAccountPersonEdmFromAccountPersons(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.accountPersons(key).reference()
      .remove(target);
  }
  public contracts(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<ContractEdm> { 
    return this.entity(key).navigationProperty<ContractEdm>('contracts'); 
  }
  public fetchContracts(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<ContractEdm>) {
    return this.fetchNavigationProperty<ContractEdm>(
      this.contracts(key), 
      'entities', options) as Observable<ODataEntities<ContractEdm>>;
  }
  public addContractEdmToContracts(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntities<ContractEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contracts(key).reference()
      .add(target);
  }
  public removeContractEdmFromContracts(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContractEdm>>, etag?: string} = {}): Observable<any> {
    return this.contracts(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public creditRequests(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<CreditRequestEdm> { 
    return this.entity(key).navigationProperty<CreditRequestEdm>('creditRequests'); 
  }
  public fetchCreditRequests(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<CreditRequestEdm>) {
    return this.fetchNavigationProperty<CreditRequestEdm>(
      this.creditRequests(key), 
      'entities', options) as Observable<ODataEntities<CreditRequestEdm>>;
  }
  public addCreditRequestEdmToCreditRequests(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntities<CreditRequestEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditRequests(key).reference()
      .add(target);
  }
  public removeCreditRequestEdmFromCreditRequests(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditRequestEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditRequests(key).reference()
      .remove(target);
  }
  public customerAgency(key: EntityKey<AccountEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgency'); 
  }
  public fetchCustomerAgency(key: EntityKey<AccountEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgency(key), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyEdm>>;
  }
  public setCustomerAgencyEdmAsCustomerAgency(key: EntityKey<AccountEdm>, target: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerAgencyEdmAsCustomerAgency(key: EntityKey<AccountEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .unset({etag});
  }
  //#endregion
}
