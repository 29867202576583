//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PersonEdm } from './personedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PersonEdmEntityConfig = {
  name: 'PersonEdm',
  keys: [{name: 'personGuid'}],
  fields: {
    personGuid: {type: 'Edm.Guid', nullable: false},
    identityGuid: {type: 'Edm.Guid'},
    firstName: {type: 'Edm.String'},
    middleNames: {type: 'Edm.String'},
    surname: {type: 'Edm.String'},
    preferredName: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    userType: {type: 'Edm.Int16'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    ivrApprover: {type: 'Edm.Boolean'},
    isContact: {type: 'Edm.Boolean'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    accountPersons: {type: 'OptiaApi.DataDomain.EFEntities.AccountPersonEdm', collection: true, navigation: true},
    actionedByChangeSets: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm', collection: true, navigation: true},
    agencyPersons: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonEdm', collection: true, navigation: true},
    agencyPersonWfRoles: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyPersonWfRoleEdm', collection: true, navigation: true},
    approvedByTransferLogs: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.TransferLogEdm', collection: true, navigation: true},
    audits: {type: 'OptiaApi.DataDomain.EFEntities.Admin.AuditEdm', collection: true, navigation: true},
    changeSets: {type: 'OptiaApi.DataDomain.EFEntities.Admin.ChangeSetEdm', collection: true, navigation: true},
    contractPersons: {type: 'OptiaApi.DataDomain.EFEntities.ContractPersonEdm', collection: true, navigation: true},
    createdByAccounts: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', collection: true, navigation: true},
    createdByAddresses: {type: 'OptiaApi.DataDomain.EFEntities.AddressEdm', collection: true, navigation: true},
    createdByAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', collection: true, navigation: true},
    createdByAssignments: {type: 'OptiaApi.DataDomain.EFEntities.AssignmentEdm', collection: true, navigation: true},
    createdByBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', collection: true, navigation: true},
    createdByCharities: {type: 'OptiaApi.DataDomain.EFEntities.CharityEdm', collection: true, navigation: true},
    createdByCompaniesHouseDetails: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm', collection: true, navigation: true},
    createdByContracts: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', collection: true, navigation: true},
    createdByCreditAssignments: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditAssignmentEdm', collection: true, navigation: true},
    createdByCreditChecks: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditCheckEdm', collection: true, navigation: true},
    createdByCreditLimits: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditLimitEdm', collection: true, navigation: true},
    createdByCreditRequests: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CreditRequestEdm', collection: true, navigation: true},
    createdByCustomers: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', collection: true, navigation: true},
    createdByMiReportGroups: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupEdm', collection: true, navigation: true},
    createdByMiReportTypes: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeEdm', collection: true, navigation: true},
    createdByMiReportTypeValidationRules: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypeValidationRuleEdm', collection: true, navigation: true},
    createdByOptiaNews: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm', collection: true, navigation: true},
    createdByPermissions: {type: 'OptiaApi.DataDomain.EFEntities.Admin.PermissionEdm', collection: true, navigation: true},
    createdByPeople: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', collection: true, navigation: true},
    createdByPhoneNumbers: {type: 'OptiaApi.DataDomain.EFEntities.PhoneNumberEdm', collection: true, navigation: true},
    createdByPurchaseOrders: {type: 'OptiaApi.DataDomain.EFEntities.PurchaseOrderEdm', collection: true, navigation: true},
    createdByRefDataItems: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataItemEdm', collection: true, navigation: true},
    createdByRefDataTypes: {type: 'OptiaApi.DataDomain.EFEntities.RefData.RefDataTypeEdm', collection: true, navigation: true},
    createdByRegExes: {type: 'OptiaApi.DataDomain.EFEntities.Admin.RegExEdm', collection: true, navigation: true},
    createdBySuppliers: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierEdm', collection: true, navigation: true},
    createdByUserGroups: {type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupEdm', collection: true, navigation: true},
    createdByWorkers: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', collection: true, navigation: true},
    createdByWorkerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', collection: true, navigation: true},
    customerAgencyPersons: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyPersonEdm', collection: true, navigation: true},
    customerPersons: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerPersonEdm', collection: true, navigation: true},
    expiredByOptiaNews: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm', collection: true, navigation: true},
    lastEditedByOptiaNews: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewEdm', collection: true, navigation: true},
    miReportGroupPersons: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportGroupPersonEdm', collection: true, navigation: true},
    miReportTypePersons: {type: 'OptiaApi.DataDomain.EFEntities.Reporting.MiReportTypePersonEdm', collection: true, navigation: true},
    notificationPersons: {type: 'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm', collection: true, navigation: true},
    optiaNewsPersons: {type: 'OptiaApi.DataDomain.EFEntities.Notify.OptiaNewsPersonEdm', collection: true, navigation: true},
    personAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonAddressEdm', collection: true, navigation: true},
    personDepartments: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonDepartmentEdm', collection: true, navigation: true},
    personPhoneNumbers: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonPhoneNumberEdm', collection: true, navigation: true},
    personUserGroups: {type: 'OptiaApi.DataDomain.EFEntities.Admin.PersonUserGroupEdm', collection: true, navigation: true},
    supplierPersons: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierPersonEdm', collection: true, navigation: true},
    uploadedByDocuments: {type: 'OptiaApi.DataDomain.EFEntities.DocumentEdm', collection: true, navigation: true},
    workerPersons: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerPersonEdm', collection: true, navigation: true},
    workerTypePersons: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypePersonEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<PersonEdm>;
//#endregion