import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";

import { BehaviorSubject, Observable, catchError, finalize, of } from "rxjs";

import { UserAnnouncementsService } from "../services/user-announcements.service";
import { NotificationEdm } from "@app/odata";
import { NotificationsApiResponse } from "../../system/models/announcements-api-response";
import { PersonAnnouncementInfo } from "../models/person-announcement-info";


export class AnnouncementsDataSource implements DataSource<PersonAnnouncementInfo> {

    public announcementsSubject = new BehaviorSubject<PersonAnnouncementInfo[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private totalItemsCountSubject = new BehaviorSubject<number>(0);

    public loading$ = this.loadingSubject.asObservable();
    public totalItemsCount$ = this.totalItemsCountSubject.asObservable();

  constructor(private announcementsService: UserAnnouncementsService) {}

    connect(collectionViewer: CollectionViewer): Observable<PersonAnnouncementInfo[]> {
        return this.announcementsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.announcementsSubject.complete();
        this.loadingSubject.complete();
        this.totalItemsCountSubject.complete();
    }

  searchMyAnnouncements(personGuid: string, filter: string, sortColumn: string, sortDirection: string, pageIndex: number, pageSize: number, typeFilter?: number) {
        this.loadingSubject.next(true);

    this.announcementsService.getMyAnnouncements(personGuid, filter, sortColumn, sortDirection, pageIndex, pageSize, typeFilter).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
          .subscribe((res: any) => {
          this.announcementsSubject.next((res as NotificationsApiResponse).announs.map((ann) => {
            return {
                ...ann.notification,
                personGuid: ann.personGuid,
                status: ann.status,
                dateRead: ann.dateRead
            } as PersonAnnouncementInfo
            }));

          this.totalItemsCountSubject.next((res as NotificationsApiResponse).total);
        });
    }
}