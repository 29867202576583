//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskRoleEdm } from './wftaskroleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskRoleEdmEntityConfig = {
  name: 'WfTaskRoleEdm',
  keys: [{name: 'roleGuid'},{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    roleGuid: {type: 'Edm.Guid', nullable: false},
    roleType: {type: 'Edm.Int32'},
    roleGuidWfRole: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRoleEdm', navigation: true, referentials: [{property: 'roleGuid', referencedProperty: 'wfRoleGuid'}]},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfTaskRoleEdm>;
//#endregion