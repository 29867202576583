//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskPermissionEdm } from './wftaskpermissionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskPermissionEdmEntityConfig = {
  name: 'WfTaskPermissionEdm',
  keys: [{name: 'permissionGuid'},{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid'},
    permissionGuid: {type: 'Edm.Guid'},
    permission: {type: 'OptiaApi.DataDomain.EFEntities.Admin.PermissionEdm', navigation: true, referentials: [{property: 'permissionGuid', referencedProperty: 'permissionGuid'}]},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfTaskPermissionEdm>;
//#endregion