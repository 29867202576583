//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerBankDetailEdm } from './customerbankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerBankDetailEdmEntityConfig = {
  name: 'CustomerBankDetailEdm',
  keys: [{name: 'bankDetailsGuid'},{name: 'customerGuid'},{name: 'fromDate'}],
  fields: {
    customerGuid: {type: 'Edm.Guid'},
    bankDetailsGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]},
    bankDetail: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', navigation: true, referentials: [{property: 'bankDetailsGuid', referencedProperty: 'bankDetailsGuid'}]}
  }
} as StructuredTypeConfig<CustomerBankDetailEdm>;
//#endregion