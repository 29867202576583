import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PersonEdm } from '@app/odata';
import { CommonService } from '@app/shared/services/common.service';
import { PersonAnnouncementInfo } from '@app/shared/models/person-announcement-info';

@Component({
  selector: 'app-announcement-details-dialog',
  templateUrl: './announcement-details-dialog.component.html',
  styleUrls: ['./announcement-details-dialog.component.scss']
})
export class AnnouncementDetailsDialogComponent {

  public announcement: PersonAnnouncementInfo;
  
  public loggedInUser: PersonEdm;

  public canUpdate = false;
  public buttonDisabled = false;

  constructor(
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public currentDialogRef: MatDialogRef<AnnouncementDetailsDialogComponent>
  ) {
    if (data) {
      //Set Objects
      this.loggedInUser = data.loggedInUser;
      this.announcement = data.announcement;
    }

  }


  btnClose_click() {

    this.currentDialogRef.close(null);
  }
}
