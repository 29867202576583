import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions,
  ODataBaseService
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DocumentDetailEdm } from './DataDomain/EFEntities/documentdetailedm.complex';
import { WorkerDetailEdm } from './DataDomain/EFEntities/workerdetailedm.complex';
import { AgencyDetailEdm } from './DataDomain/EFEntities/Agency/agencydetailedm.complex';
import { AgencyPermissionDetailEdm } from './DataDomain/EFEntities/Admin/agencypermissiondetailedm.complex';
import { BackOfficePermissionDetailEdm } from './DataDomain/EFEntities/Admin/backofficepermissiondetailedm.complex';
import { UserGroupDetailEdm } from './DataDomain/EFEntities/Admin/usergroupdetailedm.complex';
import { InputValidationTaskEdm } from './DataDomain/EFEntities/Reporting/inputvalidationtaskedm.complex';
import { MiReportCombinationEdm } from './DataDomain/EFEntities/Reporting/mireportcombinationedm.complex';
import { ReportDetailEdm } from './DataDomain/EFEntities/Reporting/reportdetailedm.complex';
import { ReportApprovalDetailEdm } from './DataDomain/EFEntities/Reporting/reportapprovaldetailedm.complex';
import { ReportGroupsWithTypeEdm } from './DataDomain/EFEntities/Reporting/reportgroupswithtypeedm.complex';
import { TransferDetailEdm } from './DataDomain/EFEntities/Reporting/transferdetailedm.complex';
import { TransferLogDetailEdm } from './DataDomain/EFEntities/Reporting/transferlogdetailedm.complex';
import { CustomerAgencyDetailEdm } from './DataDomain/EFEntities/Customer/customeragencydetailedm.complex';
import { CustomerDetailEdm } from './DataDomain/EFEntities/Customer/customerdetailedm.complex';
import { OptiaNewsDetailEdm } from './DataDomain/EFEntities/Notify/optianewsdetailedm.complex';
import { PersonDetailEdm } from './DataDomain/EFEntities/Person/persondetailedm.complex';
import { SupplierWorkerTypeDetailEdm } from './DataDomain/EFEntities/Supplier/supplierworkertypedetailedm.complex';
import { OutstandingActionEdm } from './DataDomain/EFEntities/Workflow/outstandingactionedm.complex';
import { WorkerTypeDetailEdm } from './DataDomain/EFEntities/Worker/workertypedetailedm.complex';
import { OptiaNewsDto } from './ServiceLayer/DTOs/optianewsdto.complex';
//#endregion

@Injectable()
export class ContainerService extends ODataBaseService {

  constructor(client: ODataClient) {
    super(client, 'ContainerContainer', 'Optia2023');
  }

  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  public agencyDetails(): ODataFunctionResource<null, AgencyDetailEdm> { 
    return this.client.function<null, AgencyDetailEdm>('OptiaApi.AgencyDetails', this.apiNameOrEntityType);
  }
  public callAgencyDetails(options?: ODataFunctionOptions<AgencyDetailEdm>) {
    return this.callFunction<null, AgencyDetailEdm>(
      null, 
      this.agencyDetails(), 
      'entity', options) as Observable<ODataEntity<AgencyDetailEdm>>;
  }
  public agencyPermissionDetails(): ODataFunctionResource<null, AgencyPermissionDetailEdm> { 
    return this.client.function<null, AgencyPermissionDetailEdm>('OptiaApi.AgencyPermissionDetails', this.apiNameOrEntityType);
  }
  public callAgencyPermissionDetails(options?: ODataFunctionOptions<AgencyPermissionDetailEdm>) {
    return this.callFunction<null, AgencyPermissionDetailEdm>(
      null, 
      this.agencyPermissionDetails(), 
      'entity', options) as Observable<ODataEntity<AgencyPermissionDetailEdm>>;
  }
  public backOfficePermissionDetails(): ODataFunctionResource<null, BackOfficePermissionDetailEdm> { 
    return this.client.function<null, BackOfficePermissionDetailEdm>('OptiaApi.BackOfficePermissionDetails', this.apiNameOrEntityType);
  }
  public callBackOfficePermissionDetails(options?: ODataFunctionOptions<BackOfficePermissionDetailEdm>) {
    return this.callFunction<null, BackOfficePermissionDetailEdm>(
      null, 
      this.backOfficePermissionDetails(), 
      'entity', options) as Observable<ODataEntity<BackOfficePermissionDetailEdm>>;
  }
  public customerAgencyDetails(): ODataFunctionResource<null, CustomerAgencyDetailEdm> { 
    return this.client.function<null, CustomerAgencyDetailEdm>('OptiaApi.CustomerAgencyDetails', this.apiNameOrEntityType);
  }
  public callCustomerAgencyDetails(options?: ODataFunctionOptions<CustomerAgencyDetailEdm>) {
    return this.callFunction<null, CustomerAgencyDetailEdm>(
      null, 
      this.customerAgencyDetails(), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyDetailEdm>>;
  }
  public customerDetails(): ODataFunctionResource<null, CustomerDetailEdm> { 
    return this.client.function<null, CustomerDetailEdm>('OptiaApi.CustomerDetails', this.apiNameOrEntityType);
  }
  public callCustomerDetails(options?: ODataFunctionOptions<CustomerDetailEdm>) {
    return this.callFunction<null, CustomerDetailEdm>(
      null, 
      this.customerDetails(), 
      'entity', options) as Observable<ODataEntity<CustomerDetailEdm>>;
  }
  public documentDetails(): ODataFunctionResource<null, DocumentDetailEdm> { 
    return this.client.function<null, DocumentDetailEdm>('OptiaApi.DocumentDetails', this.apiNameOrEntityType);
  }
  public callDocumentDetails(options?: ODataFunctionOptions<DocumentDetailEdm>) {
    return this.callFunction<null, DocumentDetailEdm>(
      null, 
      this.documentDetails(), 
      'entity', options) as Observable<ODataEntity<DocumentDetailEdm>>;
  }
  public inputValidationTasks(): ODataFunctionResource<null, InputValidationTaskEdm> { 
    return this.client.function<null, InputValidationTaskEdm>('OptiaApi.InputValidationTasks', this.apiNameOrEntityType);
  }
  public callInputValidationTasks(options?: ODataFunctionOptions<InputValidationTaskEdm>) {
    return this.callFunction<null, InputValidationTaskEdm>(
      null, 
      this.inputValidationTasks(), 
      'entity', options) as Observable<ODataEntity<InputValidationTaskEdm>>;
  }
  public miReportCombinations(): ODataFunctionResource<null, MiReportCombinationEdm> { 
    return this.client.function<null, MiReportCombinationEdm>('OptiaApi.MiReportCombinations', this.apiNameOrEntityType);
  }
  public callMiReportCombinations(options?: ODataFunctionOptions<MiReportCombinationEdm>) {
    return this.callFunction<null, MiReportCombinationEdm>(
      null, 
      this.miReportCombinations(), 
      'entity', options) as Observable<ODataEntity<MiReportCombinationEdm>>;
  }
  public optiaNewsDetails(): ODataFunctionResource<null, OptiaNewsDetailEdm> { 
    return this.client.function<null, OptiaNewsDetailEdm>('OptiaApi.OptiaNewsDetails', this.apiNameOrEntityType);
  }
  public callOptiaNewsDetails(options?: ODataFunctionOptions<OptiaNewsDetailEdm>) {
    return this.callFunction<null, OptiaNewsDetailEdm>(
      null, 
      this.optiaNewsDetails(), 
      'entity', options) as Observable<ODataEntity<OptiaNewsDetailEdm>>;
  }
  public outstandingActions(): ODataFunctionResource<null, OutstandingActionEdm> { 
    return this.client.function<null, OutstandingActionEdm>('OptiaApi.OutstandingActions', this.apiNameOrEntityType);
  }
  public callOutstandingActions(options?: ODataFunctionOptions<OutstandingActionEdm>) {
    return this.callFunction<null, OutstandingActionEdm>(
      null, 
      this.outstandingActions(), 
      'entity', options) as Observable<ODataEntity<OutstandingActionEdm>>;
  }
  public personDetails(): ODataFunctionResource<null, PersonDetailEdm> { 
    return this.client.function<null, PersonDetailEdm>('OptiaApi.PersonDetails', this.apiNameOrEntityType);
  }
  public callPersonDetails(options?: ODataFunctionOptions<PersonDetailEdm>) {
    return this.callFunction<null, PersonDetailEdm>(
      null, 
      this.personDetails(), 
      'entity', options) as Observable<ODataEntity<PersonDetailEdm>>;
  }
  public reportDetails(): ODataFunctionResource<null, ReportDetailEdm> { 
    return this.client.function<null, ReportDetailEdm>('OptiaApi.ReportDetails', this.apiNameOrEntityType);
  }
  public callReportDetails(options?: ODataFunctionOptions<ReportDetailEdm>) {
    return this.callFunction<null, ReportDetailEdm>(
      null, 
      this.reportDetails(), 
      'entity', options) as Observable<ODataEntity<ReportDetailEdm>>;
  }
  public reportApprovalDetails(): ODataFunctionResource<null, ReportApprovalDetailEdm> { 
    return this.client.function<null, ReportApprovalDetailEdm>('OptiaApi.ReportApprovalDetails', this.apiNameOrEntityType);
  }
  public callReportApprovalDetails(options?: ODataFunctionOptions<ReportApprovalDetailEdm>) {
    return this.callFunction<null, ReportApprovalDetailEdm>(
      null, 
      this.reportApprovalDetails(), 
      'entity', options) as Observable<ODataEntity<ReportApprovalDetailEdm>>;
  }
  public reportGroupsWithTypes(): ODataFunctionResource<null, ReportGroupsWithTypeEdm> { 
    return this.client.function<null, ReportGroupsWithTypeEdm>('OptiaApi.ReportGroupsWithTypes', this.apiNameOrEntityType);
  }
  public callReportGroupsWithTypes(options?: ODataFunctionOptions<ReportGroupsWithTypeEdm>) {
    return this.callFunction<null, ReportGroupsWithTypeEdm>(
      null, 
      this.reportGroupsWithTypes(), 
      'entity', options) as Observable<ODataEntity<ReportGroupsWithTypeEdm>>;
  }
  public supplierWorkerTypeDetails(): ODataFunctionResource<null, SupplierWorkerTypeDetailEdm> { 
    return this.client.function<null, SupplierWorkerTypeDetailEdm>('OptiaApi.SupplierWorkerTypeDetails', this.apiNameOrEntityType);
  }
  public callSupplierWorkerTypeDetails(options?: ODataFunctionOptions<SupplierWorkerTypeDetailEdm>) {
    return this.callFunction<null, SupplierWorkerTypeDetailEdm>(
      null, 
      this.supplierWorkerTypeDetails(), 
      'entity', options) as Observable<ODataEntity<SupplierWorkerTypeDetailEdm>>;
  }
  public transferDetails(): ODataFunctionResource<null, TransferDetailEdm> { 
    return this.client.function<null, TransferDetailEdm>('OptiaApi.TransferDetails', this.apiNameOrEntityType);
  }
  public callTransferDetails(options?: ODataFunctionOptions<TransferDetailEdm>) {
    return this.callFunction<null, TransferDetailEdm>(
      null, 
      this.transferDetails(), 
      'entity', options) as Observable<ODataEntity<TransferDetailEdm>>;
  }
  public transferLogDetails(): ODataFunctionResource<null, TransferLogDetailEdm> { 
    return this.client.function<null, TransferLogDetailEdm>('OptiaApi.TransferLogDetails', this.apiNameOrEntityType);
  }
  public callTransferLogDetails(options?: ODataFunctionOptions<TransferLogDetailEdm>) {
    return this.callFunction<null, TransferLogDetailEdm>(
      null, 
      this.transferLogDetails(), 
      'entity', options) as Observable<ODataEntity<TransferLogDetailEdm>>;
  }
  public userGroupDetails(): ODataFunctionResource<null, UserGroupDetailEdm> { 
    return this.client.function<null, UserGroupDetailEdm>('OptiaApi.UserGroupDetails', this.apiNameOrEntityType);
  }
  public callUserGroupDetails(options?: ODataFunctionOptions<UserGroupDetailEdm>) {
    return this.callFunction<null, UserGroupDetailEdm>(
      null, 
      this.userGroupDetails(), 
      'entity', options) as Observable<ODataEntity<UserGroupDetailEdm>>;
  }
  public workerDetails(): ODataFunctionResource<null, WorkerDetailEdm> { 
    return this.client.function<null, WorkerDetailEdm>('OptiaApi.WorkerDetails', this.apiNameOrEntityType);
  }
  public callWorkerDetails(options?: ODataFunctionOptions<WorkerDetailEdm>) {
    return this.callFunction<null, WorkerDetailEdm>(
      null, 
      this.workerDetails(), 
      'entity', options) as Observable<ODataEntity<WorkerDetailEdm>>;
  }
  public workerTypeDetails(): ODataFunctionResource<null, WorkerTypeDetailEdm> { 
    return this.client.function<null, WorkerTypeDetailEdm>('OptiaApi.WorkerTypeDetails', this.apiNameOrEntityType);
  }
  public callWorkerTypeDetails(options?: ODataFunctionOptions<WorkerTypeDetailEdm>) {
    return this.callFunction<null, WorkerTypeDetailEdm>(
      null, 
      this.workerTypeDetails(), 
      'entity', options) as Observable<ODataEntity<WorkerTypeDetailEdm>>;
  }
  public reportDetailsWeekly(): ODataFunctionResource<null, ReportDetailEdm> { 
    return this.client.function<null, ReportDetailEdm>('OptiaApi.ReportDetailsWeekly', this.apiNameOrEntityType);
  }
  public callReportDetailsWeekly(options?: ODataFunctionOptions<ReportDetailEdm>) {
    return this.callFunction<null, ReportDetailEdm>(
      null, 
      this.reportDetailsWeekly(), 
      'entity', options) as Observable<ODataEntity<ReportDetailEdm>>;
  }
  public reportDetailsMonthly(): ODataFunctionResource<null, ReportDetailEdm> { 
    return this.client.function<null, ReportDetailEdm>('OptiaApi.ReportDetailsMonthly', this.apiNameOrEntityType);
  }
  public callReportDetailsMonthly(options?: ODataFunctionOptions<ReportDetailEdm>) {
    return this.callFunction<null, ReportDetailEdm>(
      null, 
      this.reportDetailsMonthly(), 
      'entity', options) as Observable<ODataEntity<ReportDetailEdm>>;
  }
  public reportDetailsValidation(): ODataFunctionResource<null, ReportApprovalDetailEdm> { 
    return this.client.function<null, ReportApprovalDetailEdm>('OptiaApi.ReportDetailsValidation', this.apiNameOrEntityType);
  }
  public callReportDetailsValidation(options?: ODataFunctionOptions<ReportApprovalDetailEdm>) {
    return this.callFunction<null, ReportApprovalDetailEdm>(
      null, 
      this.reportDetailsValidation(), 
      'entity', options) as Observable<ODataEntity<ReportApprovalDetailEdm>>;
  }
  public myOptiaNewsDetails(): ODataFunctionResource<null, OptiaNewsDto> { 
    return this.client.function<null, OptiaNewsDto>('OptiaApi.MyOptiaNewsDetails', this.apiNameOrEntityType);
  }
  public callMyOptiaNewsDetails(options?: ODataFunctionOptions<OptiaNewsDto>) {
    return this.callFunction<null, OptiaNewsDto>(
      null, 
      this.myOptiaNewsDetails(), 
      'entity', options) as Observable<ODataEntity<OptiaNewsDto>>;
  }
  public searchCustomerDetails(): ODataFunctionResource<{filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, warningFilter: number, subtypeFilter: string, charityFilter: number, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm> { 
    return this.client.function<{filter: string, statusFilter: number, statusAgencyFilter: number, typeFilter: number, warningFilter: number, subtypeFilter: string, charityFilter: number, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm>('OptiaApi.SearchCustomerDetails', this.apiNameOrEntityType);
  }
  public callSearchCustomerDetails(filter: string, statusFilter: number | undefined, statusAgencyFilter: number | undefined, typeFilter: number | undefined, warningFilter: number | undefined, subtypeFilter: string | undefined, charityFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string, options?: ODataFunctionOptions<CustomerDetailEdm>) {
    return this.callFunction<{filter: string, statusFilter: number | undefined, statusAgencyFilter: number | undefined, typeFilter: number | undefined, warningFilter: number | undefined, subtypeFilter: string | undefined, charityFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerDetailEdm>(
      {filter, statusFilter, statusAgencyFilter, typeFilter, warningFilter, subtypeFilter, charityFilter, skip, take, sortColumn, sortOrder}, 
      this.searchCustomerDetails(), 
      'entities', options) as Observable<ODataEntities<CustomerDetailEdm>>;
  }
  public searchCustomerAgencyDetails(): ODataFunctionResource<{filter: string, warningFilter: number, typeFilter: number, statusFilter: number, statusAccountFilter: number, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerAgencyDetailEdm> { 
    return this.client.function<{filter: string, warningFilter: number, typeFilter: number, statusFilter: number, statusAccountFilter: number, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerAgencyDetailEdm>('OptiaApi.SearchCustomerAgencyDetails', this.apiNameOrEntityType);
  }
  public callSearchCustomerAgencyDetails(filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string, options?: ODataFunctionOptions<CustomerAgencyDetailEdm>) {
    return this.callFunction<{filter: string, warningFilter: number | undefined, typeFilter: number | undefined, statusFilter: number | undefined, statusAccountFilter: number | undefined, skip: number, take: number, sortColumn: string, sortOrder: string}, CustomerAgencyDetailEdm>(
      {filter, warningFilter, typeFilter, statusFilter, statusAccountFilter, skip, take, sortColumn, sortOrder}, 
      this.searchCustomerAgencyDetails(), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyDetailEdm>>;
  }
  //#endregion
}