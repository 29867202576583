//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DashboardRequestActionsByPersonGuidService } from './dashboardrequestactionsbypersonguid.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DashboardRequestActionsByPersonGuidServiceEntitySetConfig = {
  name: 'DashboardRequestActionsByPersonGuid',
  entityType: 'OptiaApi.DataDomain.EFEntities.Workflow.RequestsDashboardEdm',
  service: DashboardRequestActionsByPersonGuidService
} as EntitySetConfig;
//#endregion