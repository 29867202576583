//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OptiaNewsDtoComplexConfig } from './optianewsdto.complex.config';
import { LoggedInUserDTOEntityConfig } from './loggedinuserdto.entity.config';
import { PermissionDTOEntityConfig } from './permissiondto.entity.config';
import { SetPasswordDTOEntityConfig } from './setpassworddto.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const DTOsSchema = {
  namespace: 'OptiaApi.ServiceLayer.DTOs',
  enums: [],
  entities: [OptiaNewsDtoComplexConfig,
    LoggedInUserDTOEntityConfig,
    PermissionDTOEntityConfig,
    SetPasswordDTOEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion