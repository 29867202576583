//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskWfScheduleEdm } from './wftaskwfscheduleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskWfScheduleEdmEntityConfig = {
  name: 'WfTaskWfScheduleEdm',
  keys: [{name: 'wfScheduleGuid'},{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    wfScheduleGuid: {type: 'Edm.Guid', nullable: false},
    wfSchedule: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfScheduleEdm', navigation: true, referentials: [{property: 'wfScheduleGuid', referencedProperty: 'wfScheduleGuid'}]},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfTaskWfScheduleEdm>;
//#endregion