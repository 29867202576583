//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskPermissionsService } from './wftaskpermissions.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const WfTaskPermissionsServiceEntitySetConfig = {
  name: 'WfTaskPermissions',
  entityType: 'OptiaApi.DataDomain.EFEntities.WfTaskPermissionEdm',
  service: WfTaskPermissionsService
} as EntitySetConfig;
//#endregion