import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AccountBankDetailEdm } from './DataDomain/EFEntities/accountbankdetailedm.entity';
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
//#endregion

@Injectable()
export class AccountBankDetailsService extends ODataEntitySetService<AccountBankDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'AccountBankDetails', 'OptiaApi.DataDomain.EFEntities.AccountBankDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public account(key: EntityKey<AccountBankDetailEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('account'); 
  }
  public fetchAccount(key: EntityKey<AccountBankDetailEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.account(key), 
      'entity', options) as Observable<ODataEntity<AccountEdm>>;
  }
  public setAccountEdmAsAccount(key: EntityKey<AccountBankDetailEdm>, target: ODataEntityResource<ODataEntity<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .set(target, {etag});
  }
  public unsetAccountEdmAsAccount(key: EntityKey<AccountBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .unset({etag});
  }
  public bankDetail(key: EntityKey<AccountBankDetailEdm>): ODataNavigationPropertyResource<BankDetailEdm> { 
    return this.entity(key).navigationProperty<BankDetailEdm>('bankDetail'); 
  }
  public fetchBankDetail(key: EntityKey<AccountBankDetailEdm>, options?: ODataQueryArgumentsOptions<BankDetailEdm>) {
    return this.fetchNavigationProperty<BankDetailEdm>(
      this.bankDetail(key), 
      'entity', options) as Observable<ODataEntity<BankDetailEdm>>;
  }
  public setBankDetailEdmAsBankDetail(key: EntityKey<AccountBankDetailEdm>, target: ODataEntityResource<ODataEntity<BankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .set(target, {etag});
  }
  public unsetBankDetailEdmAsBankDetail(key: EntityKey<AccountBankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<BankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.bankDetail(key).reference()
      .unset({etag});
  }
  //#endregion
}
