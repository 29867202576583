<div class="lg my-announcements-container">
    <h1 mat-dialog-title>My Notifications</h1>
    <mat-dialog-content>
        <mat-toolbar class="person-announcements-container">
            <mat-form-field class="lg search-field-block">
                <mat-label>Search announcements</mat-label>
                <input matInput placeholder="Search announcements" #filterInput>
                <mat-icon *ngIf="filterInput.value" (click)="clearFilterInput(filterInput)">close</mat-icon>
                <mat-hint>
                    Content or Subject

                </mat-hint>
            </mat-form-field>

            <mat-form-field class="small-spacer lg">
                <mat-label>Filters</mat-label>
                <mat-select placeholder="Filter by: " [(ngModel)]="typeFilterSelection" value="0"
                    (selectionChange)="onSelectFilterChanged()" panelClass="dropdown-overlay"
                    [className]="'filter-type-select'" class="filter-type-select">
                    <!--<mat-option [value]="null"></mat-option>-->
                    <!--<mat-option *ngFor="let type of filterList" [value]="type.value">
                    {{type.text}}
                </mat-option>-->
                    <mat-option [value]="-1">Show All</mat-option>
                    <mat-option *ngFor="let type of userAnnouncementTypes" [value]="type.value">
                        {{type.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


        </mat-toolbar>
        <div>
            <div *ngIf="announcementsDataSource.loading$ | async" class="loading-indicator-middle-screen">
                <div class="spinner-container">
                    <mat-spinner class="spinner-loading-indicator"></mat-spinner>
                </div>
            </div>
            <div class="mat-elevation-z8">

                <table mat-table [dataSource]="gridDataSource" matSort matSortActive="dateSent" matSortDirection="desc"
                    class="person-announcements-grid" matSortDisableClear>
                    <ng-container matColumnDef="type" class="mat-column-type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Type
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'type'">
                                {{
                                sortChanges && sortChanges.active == "type" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-type">
                            <a class="details-cell left" aria-label="tooltip"
                                [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''"
                                (click)="showAnnouncementDetails(ann)">
                                {{ann.type | userAnnouncementsTypePipe }}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="agencyName" class="mat-column-subject">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Agency
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'agencyName'">
                                {{
                                sortChanges && sortChanges.active == "subject" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-subject"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.agencyName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subject" class="mat-column-subject">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Subject
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'subject'">
                                {{
                                sortChanges && sortChanges.active == "subject" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-subject"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.subject}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="content" class="mat-column-content">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Content
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'content'">
                                {{
                                sortChanges && sortChanges.active == "content" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-content"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.content}}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="dateSent" class="mat-column-date-sent">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date Sent
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'dateSent'">
                                {{
                                sortChanges && sortChanges.active == "dateSent" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-date-sent"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.dateSent | date:'dd/MM/yyyy' }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="expiryDate" class="mat-column-expiry-date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Expiry Date
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'expiryDate'">
                                {{
                                sortChanges && sortChanges.active == "expiryDate" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-expiry-date"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.expiryDate | date:'dd/MM/yyyy' }}
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="status" class="mat-column-status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                            <mat-icon [class.sorted]="sortChanges && sortChanges.active == 'status'">
                                {{
                                sortChanges && sortChanges.active == "status" ? sortChanges.direction == "asc" ?
                                "keyboard_arrow_up" : "keyboard_arrow_down" : "unfold_more"
                                }}
                            </mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ann" class="mat-column-status"
                            [ngClass]="ann.status === userAnnouncementStatusesEnum.Unread ? 'unread-row-cell': ''">
                            {{ann.status | userAnnouncementsStatusPipe }}
                        </td>

                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                            No results to display.
                        </td>
                    </tr>

                </table>
                <div class="row pagination">
                    <div class="col gotoPage-label">
                        <mat-label class="label">Go to page:</mat-label>
                    </div>
                    <div class="col page-select">
                        <input class="jump-to-page-number" matInput placeholder="Page #" #jumpToPageInput appOnlyNumber
                            maxlength="5">
                    </div>
                    <mat-paginator class="col mat-paginator" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
                        aria-label="Select items per page">
                    </mat-paginator>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions style="float:right">
        <button mat-raised-button color="warn" (click)="btnClose_click()">Close</button>
    </mat-dialog-actions>
</div>