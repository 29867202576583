<h3>{{ welcomeMessage }}, {{ userFullName }}</h3>
<!-- <app-requests-approval *ngIf="canSeeRequestsApprovalWidget"></app-requests-approval> -->
<div class="card-container">

  <app-details-card *ngIf="canSeeIVRApprovalWidget" #taskListContainer title="My Tasks" class="information-leaf task-grid">
    <app-ivr-approval-card></app-ivr-approval-card>
  </app-details-card>

  <app-details-card *ngIf="canSeeMyAllRequestsCard" #requestListContainer title="{{allMyRequestsCardTitle}}" class="information-leaf task-grid">
    <app-requests-card></app-requests-card>
  </app-details-card>

  <app-details-card *ngIf="canSeeRequestsApprovalWidget" #taskListContainer title="My Tasks" class="information-leaf task-grid">
    <app-tasks-card></app-tasks-card>
  </app-details-card>

  <!--<app-details-card #taskCreditListContainer title="Credit Request" class="information-leaf task-grid">
    <app-requests-approval></app-requests-approval>
  </app-details-card>-->

  <app-details-card *ngIf="canViewOptiaNews" #optiaNewsContainer title="News" class="information-leaf news-grid">
    <app-news-card></app-news-card>
  </app-details-card>
</div>
