//#region ODataApiGen ODataImports
import {
  EntityContainerConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountsServiceEntitySetConfig } from './accounts.service.config';
import { AccountBankDetailsServiceEntitySetConfig } from './accountbankdetails.service.config';
import { AccountPersonsServiceEntitySetConfig } from './accountpersons.service.config';
import { AddressesServiceEntitySetConfig } from './addresses.service.config';
import { AgenciesServiceEntitySetConfig } from './agencies.service.config';
import { AgencyAddressesServiceEntitySetConfig } from './agencyaddresses.service.config';
import { AgencyBankDetailsServiceEntitySetConfig } from './agencybankdetails.service.config';
import { AgencyDocumentsServiceEntitySetConfig } from './agencydocuments.service.config';
import { AgencyMtdCalendarsServiceEntitySetConfig } from './agencymtdcalendars.service.config';
import { AgencyPersonsServiceEntitySetConfig } from './agencypersons.service.config';
import { AgencyPersonWfRolesServiceEntitySetConfig } from './agencypersonwfroles.service.config';
import { AgencyReportingCategoriesServiceEntitySetConfig } from './agencyreportingcategories.service.config';
import { AgencyReportingCombinationsServiceEntitySetConfig } from './agencyreportingcombinations.service.config';
import { AgencyWorkersServiceEntitySetConfig } from './agencyworkers.service.config';
import { AssignmentsServiceEntitySetConfig } from './assignments.service.config';
import { AuditsServiceEntitySetConfig } from './audits.service.config';
import { BankDetailsServiceEntitySetConfig } from './bankdetails.service.config';
import { ChangeSetsServiceEntitySetConfig } from './changesets.service.config';
import { ChangeSetGroupsServiceEntitySetConfig } from './changesetgroups.service.config';
import { ChangeSetLinesServiceEntitySetConfig } from './changesetlines.service.config';
import { CharitiesServiceEntitySetConfig } from './charities.service.config';
import { CompaniesHouseDetailsServiceEntitySetConfig } from './companieshousedetails.service.config';
import { CompaniesHouseDetailsAddressesServiceEntitySetConfig } from './companieshousedetailsaddresses.service.config';
import { CompaniesHouseDetailsPersonsServiceEntitySetConfig } from './companieshousedetailspersons.service.config';
import { ContractsServiceEntitySetConfig } from './contracts.service.config';
import { ContractPersonsServiceEntitySetConfig } from './contractpersons.service.config';
import { ContractReportingLabelsServiceEntitySetConfig } from './contractreportinglabels.service.config';
import { CreditAssignmentsServiceEntitySetConfig } from './creditassignments.service.config';
import { CreditChecksServiceEntitySetConfig } from './creditchecks.service.config';
import { CreditLimitsServiceEntitySetConfig } from './creditlimits.service.config';
import { CreditRequestsServiceEntitySetConfig } from './creditrequests.service.config';
import { CustomersServiceEntitySetConfig } from './customers.service.config';
import { CustomerAddressesServiceEntitySetConfig } from './customeraddresses.service.config';
import { CustomerAgenciesServiceEntitySetConfig } from './customeragencies.service.config';
import { CustomerAgencyDocumentsServiceEntitySetConfig } from './customeragencydocuments.service.config';
import { CustomerAgencyPersonsServiceEntitySetConfig } from './customeragencypersons.service.config';
import { CustomerBankDetailsServiceEntitySetConfig } from './customerbankdetails.service.config';
import { CustomerDocumentsServiceEntitySetConfig } from './customerdocuments.service.config';
import { CustomerPersonsServiceEntitySetConfig } from './customerpersons.service.config';
import { DataMismatchesServiceEntitySetConfig } from './datamismatches.service.config';
import { DepartmentsServiceEntitySetConfig } from './departments.service.config';
import { DocumentsServiceEntitySetConfig } from './documents.service.config';
import { HistoricalReferencesServiceEntitySetConfig } from './historicalreferences.service.config';
import { MiReportGroupsServiceEntitySetConfig } from './mireportgroups.service.config';
import { MiReportGroupPersonsServiceEntitySetConfig } from './mireportgrouppersons.service.config';
import { MiReportTypesServiceEntitySetConfig } from './mireporttypes.service.config';
import { MiReportTypePersonsServiceEntitySetConfig } from './mireporttypepersons.service.config';
import { MiReportTypeValidationRulesServiceEntitySetConfig } from './mireporttypevalidationrules.service.config';
import { NotificationsServiceEntitySetConfig } from './notifications.service.config';
import { NotificationPersonsServiceEntitySetConfig } from './notificationpersons.service.config';
import { NotificationTemplatesServiceEntitySetConfig } from './notificationtemplates.service.config';
import { OptiaNewsServiceEntitySetConfig } from './optianews.service.config';
import { OptiaNewsDocumentsServiceEntitySetConfig } from './optianewsdocuments.service.config';
import { OptiaNewsPersonsServiceEntitySetConfig } from './optianewspersons.service.config';
import { PermissionsServiceEntitySetConfig } from './permissions.service.config';
import { PeopleServiceEntitySetConfig } from './people.service.config';
import { PersonAddressesServiceEntitySetConfig } from './personaddresses.service.config';
import { PersonDepartmentsServiceEntitySetConfig } from './persondepartments.service.config';
import { PersonPhoneNumbersServiceEntitySetConfig } from './personphonenumbers.service.config';
import { PersonUserGroupsServiceEntitySetConfig } from './personusergroups.service.config';
import { PhoneNumbersServiceEntitySetConfig } from './phonenumbers.service.config';
import { PurchaseOrdersServiceEntitySetConfig } from './purchaseorders.service.config';
import { RefDataItemsServiceEntitySetConfig } from './refdataitems.service.config';
import { RefDataTypesServiceEntitySetConfig } from './refdatatypes.service.config';
import { RegExesServiceEntitySetConfig } from './regexes.service.config';
import { ReportingCategoriesServiceEntitySetConfig } from './reportingcategories.service.config';
import { ReportingLabelsServiceEntitySetConfig } from './reportinglabels.service.config';
import { SuppliersServiceEntitySetConfig } from './suppliers.service.config';
import { SupplierAddressesServiceEntitySetConfig } from './supplieraddresses.service.config';
import { SupplierAgenciesServiceEntitySetConfig } from './supplieragencies.service.config';
import { SupplierBankDetailsServiceEntitySetConfig } from './supplierbankdetails.service.config';
import { SupplierDocumentsServiceEntitySetConfig } from './supplierdocuments.service.config';
import { SupplierPersonsServiceEntitySetConfig } from './supplierpersons.service.config';
import { SupplierWorkerTypesServiceEntitySetConfig } from './supplierworkertypes.service.config';
import { TradingStylesServiceEntitySetConfig } from './tradingstyles.service.config';
import { TransfersServiceEntitySetConfig } from './transfers.service.config';
import { TransferAgenciesServiceEntitySetConfig } from './transferagencies.service.config';
import { TransferMiReportTypesServiceEntitySetConfig } from './transfermireporttypes.service.config';
import { TransferLogsServiceEntitySetConfig } from './transferlogs.service.config';
import { TransferRevisionsServiceEntitySetConfig } from './transferrevisions.service.config';
import { UserGroupsServiceEntitySetConfig } from './usergroups.service.config';
import { UserGroupPermissionsServiceEntitySetConfig } from './usergrouppermissions.service.config';
import { WfActionsServiceEntitySetConfig } from './wfactions.service.config';
import { WfInstancesServiceEntitySetConfig } from './wfinstances.service.config';
import { WfOutcomesServiceEntitySetConfig } from './wfoutcomes.service.config';
import { WfPreRequisitesServiceEntitySetConfig } from './wfprerequisites.service.config';
import { WfProcessesServiceEntitySetConfig } from './wfprocesses.service.config';
import { WfProcessWfSchedulesServiceEntitySetConfig } from './wfprocesswfschedules.service.config';
import { WfRolesServiceEntitySetConfig } from './wfroles.service.config';
import { WfRulesServiceEntitySetConfig } from './wfrules.service.config';
import { WfSchedulesServiceEntitySetConfig } from './wfschedules.service.config';
import { WfTasksServiceEntitySetConfig } from './wftasks.service.config';
import { WfTaskContactTypesServiceEntitySetConfig } from './wftaskcontacttypes.service.config';
import { WfTaskGroupsServiceEntitySetConfig } from './wftaskgroups.service.config';
import { WfTaskPermissionsServiceEntitySetConfig } from './wftaskpermissions.service.config';
import { WfTaskRolesServiceEntitySetConfig } from './wftaskroles.service.config';
import { WfTaskTabsServiceEntitySetConfig } from './wftasktabs.service.config';
import { WfTaskTemplatesServiceEntitySetConfig } from './wftasktemplates.service.config';
import { WfTaskWfSchedulesServiceEntitySetConfig } from './wftaskwfschedules.service.config';
import { WfTemplatesServiceEntitySetConfig } from './wftemplates.service.config';
import { WorkersServiceEntitySetConfig } from './workers.service.config';
import { WorkerAddressesServiceEntitySetConfig } from './workeraddresses.service.config';
import { WorkerBankDetailsServiceEntitySetConfig } from './workerbankdetails.service.config';
import { WorkerDocumentsServiceEntitySetConfig } from './workerdocuments.service.config';
import { WorkerPersonsServiceEntitySetConfig } from './workerpersons.service.config';
import { WorkerPhoneNumbersServiceEntitySetConfig } from './workerphonenumbers.service.config';
import { WorkerTypesServiceEntitySetConfig } from './workertypes.service.config';
import { WorkerTypeBankDetailsServiceEntitySetConfig } from './workertypebankdetails.service.config';
import { WorkerTypeDocumentsServiceEntitySetConfig } from './workertypedocuments.service.config';
import { WorkerTypePersonsServiceEntitySetConfig } from './workertypepersons.service.config';
import { WorkerTypeReportingLabelsServiceEntitySetConfig } from './workertypereportinglabels.service.config';
import { LoggedInUserDTOServiceEntitySetConfig } from './loggedinuserdto.service.config';
import { PermissionDTOServiceEntitySetConfig } from './permissiondto.service.config';
import { SetPasswordDTOServiceEntitySetConfig } from './setpassworddto.service.config';
import { WfActionOutstandingEdmServiceEntitySetConfig } from './wfactionoutstandingedm.service.config';
import { ActionsByPersonGuidServiceEntitySetConfig } from './actionsbypersonguid.service.config';
import { RequestsDashboardEdmServiceEntitySetConfig } from './requestsdashboardedm.service.config';
import { DashboardRequestActionsByPersonGuidServiceEntitySetConfig } from './dashboardrequestactionsbypersonguid.service.config';
//#endregion

//#region ODataApiGen EntityContainerConfig
export const ContainerContainer = {
  name: 'Container',
  entitySets: [
    AccountsServiceEntitySetConfig,
    AccountBankDetailsServiceEntitySetConfig,
    AccountPersonsServiceEntitySetConfig,
    AddressesServiceEntitySetConfig,
    AgenciesServiceEntitySetConfig,
    AgencyAddressesServiceEntitySetConfig,
    AgencyBankDetailsServiceEntitySetConfig,
    AgencyDocumentsServiceEntitySetConfig,
    AgencyMtdCalendarsServiceEntitySetConfig,
    AgencyPersonsServiceEntitySetConfig,
    AgencyPersonWfRolesServiceEntitySetConfig,
    AgencyReportingCategoriesServiceEntitySetConfig,
    AgencyReportingCombinationsServiceEntitySetConfig,
    AgencyWorkersServiceEntitySetConfig,
    AssignmentsServiceEntitySetConfig,
    AuditsServiceEntitySetConfig,
    BankDetailsServiceEntitySetConfig,
    ChangeSetsServiceEntitySetConfig,
    ChangeSetGroupsServiceEntitySetConfig,
    ChangeSetLinesServiceEntitySetConfig,
    CharitiesServiceEntitySetConfig,
    CompaniesHouseDetailsServiceEntitySetConfig,
    CompaniesHouseDetailsAddressesServiceEntitySetConfig,
    CompaniesHouseDetailsPersonsServiceEntitySetConfig,
    ContractsServiceEntitySetConfig,
    ContractPersonsServiceEntitySetConfig,
    ContractReportingLabelsServiceEntitySetConfig,
    CreditAssignmentsServiceEntitySetConfig,
    CreditChecksServiceEntitySetConfig,
    CreditLimitsServiceEntitySetConfig,
    CreditRequestsServiceEntitySetConfig,
    CustomersServiceEntitySetConfig,
    CustomerAddressesServiceEntitySetConfig,
    CustomerAgenciesServiceEntitySetConfig,
    CustomerAgencyDocumentsServiceEntitySetConfig,
    CustomerAgencyPersonsServiceEntitySetConfig,
    CustomerBankDetailsServiceEntitySetConfig,
    CustomerDocumentsServiceEntitySetConfig,
    CustomerPersonsServiceEntitySetConfig,
    DataMismatchesServiceEntitySetConfig,
    DepartmentsServiceEntitySetConfig,
    DocumentsServiceEntitySetConfig,
    HistoricalReferencesServiceEntitySetConfig,
    MiReportGroupsServiceEntitySetConfig,
    MiReportGroupPersonsServiceEntitySetConfig,
    MiReportTypesServiceEntitySetConfig,
    MiReportTypePersonsServiceEntitySetConfig,
    MiReportTypeValidationRulesServiceEntitySetConfig,
    NotificationsServiceEntitySetConfig,
    NotificationPersonsServiceEntitySetConfig,
    NotificationTemplatesServiceEntitySetConfig,
    OptiaNewsServiceEntitySetConfig,
    OptiaNewsDocumentsServiceEntitySetConfig,
    OptiaNewsPersonsServiceEntitySetConfig,
    PermissionsServiceEntitySetConfig,
    PeopleServiceEntitySetConfig,
    PersonAddressesServiceEntitySetConfig,
    PersonDepartmentsServiceEntitySetConfig,
    PersonPhoneNumbersServiceEntitySetConfig,
    PersonUserGroupsServiceEntitySetConfig,
    PhoneNumbersServiceEntitySetConfig,
    PurchaseOrdersServiceEntitySetConfig,
    RefDataItemsServiceEntitySetConfig,
    RefDataTypesServiceEntitySetConfig,
    RegExesServiceEntitySetConfig,
    ReportingCategoriesServiceEntitySetConfig,
    ReportingLabelsServiceEntitySetConfig,
    SuppliersServiceEntitySetConfig,
    SupplierAddressesServiceEntitySetConfig,
    SupplierAgenciesServiceEntitySetConfig,
    SupplierBankDetailsServiceEntitySetConfig,
    SupplierDocumentsServiceEntitySetConfig,
    SupplierPersonsServiceEntitySetConfig,
    SupplierWorkerTypesServiceEntitySetConfig,
    TradingStylesServiceEntitySetConfig,
    TransfersServiceEntitySetConfig,
    TransferAgenciesServiceEntitySetConfig,
    TransferMiReportTypesServiceEntitySetConfig,
    TransferLogsServiceEntitySetConfig,
    TransferRevisionsServiceEntitySetConfig,
    UserGroupsServiceEntitySetConfig,
    UserGroupPermissionsServiceEntitySetConfig,
    WfActionsServiceEntitySetConfig,
    WfInstancesServiceEntitySetConfig,
    WfOutcomesServiceEntitySetConfig,
    WfPreRequisitesServiceEntitySetConfig,
    WfProcessesServiceEntitySetConfig,
    WfProcessWfSchedulesServiceEntitySetConfig,
    WfRolesServiceEntitySetConfig,
    WfRulesServiceEntitySetConfig,
    WfSchedulesServiceEntitySetConfig,
    WfTasksServiceEntitySetConfig,
    WfTaskContactTypesServiceEntitySetConfig,
    WfTaskGroupsServiceEntitySetConfig,
    WfTaskPermissionsServiceEntitySetConfig,
    WfTaskRolesServiceEntitySetConfig,
    WfTaskTabsServiceEntitySetConfig,
    WfTaskTemplatesServiceEntitySetConfig,
    WfTaskWfSchedulesServiceEntitySetConfig,
    WfTemplatesServiceEntitySetConfig,
    WorkersServiceEntitySetConfig,
    WorkerAddressesServiceEntitySetConfig,
    WorkerBankDetailsServiceEntitySetConfig,
    WorkerDocumentsServiceEntitySetConfig,
    WorkerPersonsServiceEntitySetConfig,
    WorkerPhoneNumbersServiceEntitySetConfig,
    WorkerTypesServiceEntitySetConfig,
    WorkerTypeBankDetailsServiceEntitySetConfig,
    WorkerTypeDocumentsServiceEntitySetConfig,
    WorkerTypePersonsServiceEntitySetConfig,
    WorkerTypeReportingLabelsServiceEntitySetConfig,
    LoggedInUserDTOServiceEntitySetConfig,
    PermissionDTOServiceEntitySetConfig,
    SetPasswordDTOServiceEntitySetConfig,
    WfActionOutstandingEdmServiceEntitySetConfig,
    ActionsByPersonGuidServiceEntitySetConfig,
    RequestsDashboardEdmServiceEntitySetConfig,
    DashboardRequestActionsByPersonGuidServiceEntitySetConfig
  ]
} as EntityContainerConfig;
//#endregion