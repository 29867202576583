//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountBankDetailsService } from './accountbankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AccountBankDetailsServiceEntitySetConfig = {
  name: 'AccountBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.AccountBankDetailEdm',
  service: AccountBankDetailsService
} as EntitySetConfig;
//#endregion