import { Pipe, PipeTransform } from '@angular/core';
import * as enums from '@shared/lookups/enums';


@Pipe({
    name: 'userAnnouncementsTypePipe'
})
export class UserAnnouncementsTypePipe implements PipeTransform {
    transform(value: number | undefined): string | number {

        if(typeof(value) === 'undefined') {
            return '';
        }

        value = value as number;
        // Try to lookup the value in the enums
        if(enums.UserAnnouncementTypesEnum) {
        
            if(enums.UserAnnouncementTypesEnum[value] !== undefined)
            {
                return enums.UserAnnouncementTypesEnum[value];
            }
        }
        // Default to just retuning the value if the lookups fail
        return value;
    }
}