//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyEdm } from './customeragencyedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAgencyEdmEntityConfig = {
  name: 'CustomerAgencyEdm',
  keys: [{name: 'customerAgencyGuid'}],
  fields: {
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid'},
    customerGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16'},
    fromDate: {type: 'Edm.DateTimeOffset'},
    toDate: {type: 'Edm.DateTimeOffset'},
    subStatus: {type: 'Edm.Int16'},
    verifiedBy: {type: 'Edm.Int16'},
    verificationReason: {type: 'Edm.Int16'},
    verificationDetails: {type: 'Edm.String'},
    transferredToPayrollDate: {type: 'Edm.DateTimeOffset'},
    creditLimit: {type: 'Edm.Decimal', scale: 'variable'},
    debt: {type: 'Edm.Decimal', scale: 'variable'},
    exposure: {type: 'Edm.Decimal', scale: 'variable'},
    exposureCap: {type: 'Edm.Decimal', scale: 'variable'},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    customer: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerEdm', navigation: true, referentials: [{property: 'customerGuid', referencedProperty: 'customerGuid'}]},
    accounts: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', collection: true, navigation: true},
    creditAssignments: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditAssignmentEdm', collection: true, navigation: true},
    creditRequests: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CreditRequestEdm', collection: true, navigation: true},
    customerAgencyDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyDocumentEdm', collection: true, navigation: true},
    customerAgencyPersons: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyPersonEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CustomerAgencyEdm>;
//#endregion