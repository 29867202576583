//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierWorkerTypeDetailEdm } from './supplierworkertypedetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierWorkerTypeDetailEdmComplexConfig = {
  name: 'SupplierWorkerTypeDetailEdm',
  fields: {
    workerGuid: {type: 'Edm.Guid', nullable: false},
    workerTypeGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    supplierGuid: {type: 'Edm.Guid', nullable: false},
    supplierWorkerTypeGuid: {type: 'Edm.Guid', nullable: false},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerName: {type: 'Edm.String', nullable: false},
    emailAddress: {type: 'Edm.String', nullable: false},
    workerStatus: {type: 'Edm.Int16', nullable: false},
    reference: {type: 'Edm.String'},
    workerTypeStatus: {type: 'Edm.Int16', nullable: false}
  }
} as StructuredTypeConfig<SupplierWorkerTypeDetailEdm>;
//#endregion