//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfRuleEdm } from './wfruleedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfRuleEdmEntityConfig = {
  name: 'WfRuleEdm',
  keys: [{name: 'wfRuleGuid'}],
  fields: {
    wfRuleGuid: {type: 'Edm.Guid', nullable: false},
    wfProcessGuid: {type: 'Edm.Guid', nullable: false},
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    reminderTimeout: {type: 'Edm.Int32'},
    reminderFrequency: {type: 'Edm.Int32'},
    completionTimeout: {type: 'Edm.Int32'},
    branched: {type: 'Edm.Boolean'},
    timeoutType: {type: 'Edm.Int16'},
    wfProcess: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfProcessEdm', navigation: true, referentials: [{property: 'wfProcessGuid', referencedProperty: 'wfProcessGuid'}]},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]},
    wfPreRequisites: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfPreRequisiteEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfRuleEdm>;
//#endregion