//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskTabEdm } from './wftasktabedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskTabEdmEntityConfig = {
  name: 'WfTaskTabEdm',
  keys: [{name: 'tabIndex'},{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    tabIndex: {type: 'Edm.Int32', nullable: false},
    visibility: {type: 'Edm.Int32'},
    wfTask: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskEdm', navigation: true, referentials: [{property: 'wfTaskGuid', referencedProperty: 'wfTaskGuid'}]}
  }
} as StructuredTypeConfig<WfTaskTabEdm>;
//#endregion