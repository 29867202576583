<div class="lg edit-agency-status-container">
    <h1 mat-dialog-title>Announcement Details</h1>
    <mat-dialog-content *ngIf="announcement">
        <div class="details-row">
            <div class="details-cell-label">
                <span>Type</span>
            </div>
            <div class="details-cell-value">
                <span>{{ announcement.type | userAnnouncementsTypePipe }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Subject</span>
            </div>
            <div class="details-cell-value">
                <span>{{ announcement.subject }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Content</span>
            </div>
            <div class="details-cell-value">
                <span>{{ announcement.content }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Date Sent</span>
            </div>
            <div class="details-cell-value">
                <span>{{announcement.dateSent | date:'dd/MM/yyyy' }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Expiry Date</span>
            </div>
            <div class="details-cell-value">
                <span>{{announcement.expiryDate | date:'dd/MM/yyyy' }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Status</span>
            </div>
            <div class="details-cell-value">
                <span>{{announcement.status | userAnnouncementsStatusPipe }}</span>
            </div>
        </div>
        <div class="details-row">
            <div class="details-cell-label">
                <span>Date Read</span>
            </div>
            <div class="details-cell-value">
                <span>{{announcement.dateRead | date:'dd/MM/yyyy' }}</span>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions style="float:right">
        <button mat-raised-button color="warn" class="close-button" (click)="btnClose_click()">Close</button>
    </mat-dialog-actions>
</div>
