import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { CreditRequestEdm } from './DataDomain/EFEntities/Customer/creditrequestedm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class CreditRequestsService extends ODataEntitySetService<CreditRequestEdm> {
  constructor(client: ODataClient) {
    super(client, 'CreditRequests', 'OptiaApi.DataDomain.EFEntities.Customer.CreditRequestEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public account(key: EntityKey<CreditRequestEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('account'); 
  }
  public fetchAccount(key: EntityKey<CreditRequestEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.account(key), 
      'entity', options) as Observable<ODataEntity<AccountEdm>>;
  }
  public setAccountEdmAsAccount(key: EntityKey<CreditRequestEdm>, target: ODataEntityResource<ODataEntity<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .set(target, {etag});
  }
  public unsetAccountEdmAsAccount(key: EntityKey<CreditRequestEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.account(key).reference()
      .unset({etag});
  }
  public agency(key: EntityKey<CreditRequestEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<CreditRequestEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<CreditRequestEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<CreditRequestEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public createdByPerson(key: EntityKey<CreditRequestEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<CreditRequestEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<CreditRequestEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<CreditRequestEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public customerAgency(key: EntityKey<CreditRequestEdm>): ODataNavigationPropertyResource<CustomerAgencyEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyEdm>('customerAgency'); 
  }
  public fetchCustomerAgency(key: EntityKey<CreditRequestEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyEdm>(
      this.customerAgency(key), 
      'entity', options) as Observable<ODataEntity<CustomerAgencyEdm>>;
  }
  public setCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CreditRequestEdm>, target: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerAgencyEdmAsCustomerAgency(key: EntityKey<CreditRequestEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerAgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgency(key).reference()
      .unset({etag});
  }
  //#endregion
}
