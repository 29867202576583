//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CompaniesHouseDetailsPersonEdm } from './companieshousedetailspersonedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CompaniesHouseDetailsPersonEdmEntityConfig = {
  name: 'CompaniesHouseDetailsPersonEdm',
  keys: [{name: 'companiesHouseDetailsGuid'},{name: 'companiesHouseDetailsPersonGuid'},{name: 'contactType'},{name: 'notifiedOn'}],
  fields: {
    companiesHouseDetailsGuid: {type: 'Edm.Guid'},
    companiesHouseDetailsPersonGuid: {type: 'Edm.Guid', nullable: false},
    createdBy: {type: 'Edm.Guid'},
    firstName: {type: 'Edm.String'},
    middleNames: {type: 'Edm.String'},
    surname: {type: 'Edm.String'},
    preferredName: {type: 'Edm.String'},
    jobDescription: {type: 'Edm.String'},
    emailAddress: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    contactType: {type: 'Edm.Int16', nullable: false},
    notifiedOn: {type: 'Edm.DateTimeOffset', nullable: false},
    ceasedOn: {type: 'Edm.DateTimeOffset'},
    kind: {type: 'Edm.String'},
    nationality: {type: 'Edm.String'},
    countryOfResidence: {type: 'Edm.String'},
    naturesOfControl: {type: 'Edm.String'},
    companiesHouseReference: {type: 'Edm.String'},
    companiesHouseDetail: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm', navigation: true, referentials: [{property: 'companiesHouseDetailsGuid', referencedProperty: 'companiesHouseDetailsGuid'}]}
  }
} as StructuredTypeConfig<CompaniesHouseDetailsPersonEdm>;
//#endregion