import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TradingStyleEdm } from './DataDomain/EFEntities/Agency/tradingstyleedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { SupplierWorkerTypeEdm } from './DataDomain/EFEntities/Supplier/supplierworkertypeedm.entity';
import { WorkerEdm } from './DataDomain/EFEntities/Worker/workeredm.entity';
import { WorkerTypeEdm } from './DataDomain/EFEntities/Worker/workertypeedm.entity';
import { WorkerTypeBankDetailEdm } from './DataDomain/EFEntities/Worker/workertypebankdetailedm.entity';
import { WorkerTypeDocumentEdm } from './DataDomain/EFEntities/Worker/workertypedocumentedm.entity';
import { WorkerTypePersonEdm } from './DataDomain/EFEntities/Worker/workertypepersonedm.entity';
import { WorkerTypeReportingLabelEdm } from './DataDomain/EFEntities/Worker/workertypereportinglabeledm.entity';
//#endregion

@Injectable()
export class WorkerTypesService extends ODataEntitySetService<WorkerTypeEdm> {
  constructor(client: ODataClient) {
    super(client, 'WorkerTypes', 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public createdByPerson(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public previousWorkerType(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('previousWorkerType'); 
  }
  public fetchPreviousWorkerType(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.previousWorkerType(key), 
      'entity', options) as Observable<ODataEntity<WorkerTypeEdm>>;
  }
  public setWorkerTypeEdmAsPreviousWorkerType(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.previousWorkerType(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerTypeEdmAsPreviousWorkerType(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.previousWorkerType(key).reference()
      .unset({etag});
  }
  public previousWorkerTypes(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeEdm>('previousWorkerTypes'); 
  }
  public fetchPreviousWorkerTypes(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeEdm>) {
    return this.fetchNavigationProperty<WorkerTypeEdm>(
      this.previousWorkerTypes(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeEdm>>;
  }
  public addWorkerTypeEdmToPreviousWorkerTypes(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.previousWorkerTypes(key).reference()
      .add(target);
  }
  public removeWorkerTypeEdmFromPreviousWorkerTypes(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.previousWorkerTypes(key).reference()
      .remove(target);
  }
  public supplierWorkerTypes(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<SupplierWorkerTypeEdm> { 
    return this.entity(key).navigationProperty<SupplierWorkerTypeEdm>('supplierWorkerTypes'); 
  }
  public fetchSupplierWorkerTypes(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<SupplierWorkerTypeEdm>) {
    return this.fetchNavigationProperty<SupplierWorkerTypeEdm>(
      this.supplierWorkerTypes(key), 
      'entities', options) as Observable<ODataEntities<SupplierWorkerTypeEdm>>;
  }
  public addSupplierWorkerTypeEdmToSupplierWorkerTypes(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<SupplierWorkerTypeEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierWorkerTypes(key).reference()
      .add(target);
  }
  public removeSupplierWorkerTypeEdmFromSupplierWorkerTypes(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierWorkerTypeEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierWorkerTypes(key).reference()
      .remove(target);
  }
  public tradingStyle(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<TradingStyleEdm> { 
    return this.entity(key).navigationProperty<TradingStyleEdm>('tradingStyle'); 
  }
  public fetchTradingStyle(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<TradingStyleEdm>) {
    return this.fetchNavigationProperty<TradingStyleEdm>(
      this.tradingStyle(key), 
      'entity', options) as Observable<ODataEntity<TradingStyleEdm>>;
  }
  public setTradingStyleEdmAsTradingStyle(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntity<TradingStyleEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.tradingStyle(key).reference()
      .set(target, {etag});
  }
  public unsetTradingStyleEdmAsTradingStyle(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<TradingStyleEdm>>, etag?: string} = {}): Observable<any> {
    return this.tradingStyle(key).reference()
      .unset({etag});
  }
  public worker(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerEdm> { 
    return this.entity(key).navigationProperty<WorkerEdm>('worker'); 
  }
  public fetchWorker(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerEdm>) {
    return this.fetchNavigationProperty<WorkerEdm>(
      this.worker(key), 
      'entity', options) as Observable<ODataEntity<WorkerEdm>>;
  }
  public setWorkerEdmAsWorker(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntity<WorkerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .set(target, {etag});
  }
  public unsetWorkerEdmAsWorker(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WorkerEdm>>, etag?: string} = {}): Observable<any> {
    return this.worker(key).reference()
      .unset({etag});
  }
  public workerTypeBankDetails(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeBankDetailEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeBankDetailEdm>('workerTypeBankDetails'); 
  }
  public fetchWorkerTypeBankDetails(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeBankDetailEdm>) {
    return this.fetchNavigationProperty<WorkerTypeBankDetailEdm>(
      this.workerTypeBankDetails(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeBankDetailEdm>>;
  }
  public addWorkerTypeBankDetailEdmToWorkerTypeBankDetails(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeBankDetails(key).reference()
      .add(target);
  }
  public removeWorkerTypeBankDetailEdmFromWorkerTypeBankDetails(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeBankDetails(key).reference()
      .remove(target);
  }
  public workerTypeDocuments(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeDocumentEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeDocumentEdm>('workerTypeDocuments'); 
  }
  public fetchWorkerTypeDocuments(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeDocumentEdm>) {
    return this.fetchNavigationProperty<WorkerTypeDocumentEdm>(
      this.workerTypeDocuments(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeDocumentEdm>>;
  }
  public addWorkerTypeDocumentEdmToWorkerTypeDocuments(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeDocuments(key).reference()
      .add(target);
  }
  public removeWorkerTypeDocumentEdmFromWorkerTypeDocuments(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeDocuments(key).reference()
      .remove(target);
  }
  public workerTypePersons(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypePersonEdm> { 
    return this.entity(key).navigationProperty<WorkerTypePersonEdm>('workerTypePersons'); 
  }
  public fetchWorkerTypePersons(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypePersonEdm>) {
    return this.fetchNavigationProperty<WorkerTypePersonEdm>(
      this.workerTypePersons(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypePersonEdm>>;
  }
  public addWorkerTypePersonEdmToWorkerTypePersons(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<WorkerTypePersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypePersons(key).reference()
      .add(target);
  }
  public removeWorkerTypePersonEdmFromWorkerTypePersons(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypePersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypePersons(key).reference()
      .remove(target);
  }
  public workerTypeReportingLabels(key: EntityKey<WorkerTypeEdm>): ODataNavigationPropertyResource<WorkerTypeReportingLabelEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeReportingLabelEdm>('workerTypeReportingLabels'); 
  }
  public fetchWorkerTypeReportingLabels(key: EntityKey<WorkerTypeEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeReportingLabelEdm>) {
    return this.fetchNavigationProperty<WorkerTypeReportingLabelEdm>(
      this.workerTypeReportingLabels(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeReportingLabelEdm>>;
  }
  public addWorkerTypeReportingLabelEdmToWorkerTypeReportingLabels(key: EntityKey<WorkerTypeEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeReportingLabelEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeReportingLabels(key).reference()
      .add(target);
  }
  public removeWorkerTypeReportingLabelEdmFromWorkerTypeReportingLabels(key: EntityKey<WorkerTypeEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeReportingLabelEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeReportingLabels(key).reference()
      .remove(target);
  }
  //#endregion
}
