//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierWorkerTypeDetailEdmComplexConfig } from './supplierworkertypedetailedm.complex.config';
import { SupplierEdmEntityConfig } from './supplieredm.entity.config';
import { SupplierAddressEdmEntityConfig } from './supplieraddressedm.entity.config';
import { SupplierAgencyEdmEntityConfig } from './supplieragencyedm.entity.config';
import { SupplierBankDetailEdmEntityConfig } from './supplierbankdetailedm.entity.config';
import { SupplierDocumentEdmEntityConfig } from './supplierdocumentedm.entity.config';
import { SupplierPersonEdmEntityConfig } from './supplierpersonedm.entity.config';
import { SupplierWorkerTypeEdmEntityConfig } from './supplierworkertypeedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const SupplierSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Supplier',
  enums: [],
  entities: [SupplierWorkerTypeDetailEdmComplexConfig,
    SupplierEdmEntityConfig,
    SupplierAddressEdmEntityConfig,
    SupplierAgencyEdmEntityConfig,
    SupplierBankDetailEdmEntityConfig,
    SupplierDocumentEdmEntityConfig,
    SupplierPersonEdmEntityConfig,
    SupplierWorkerTypeEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion