import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationEdm, PersonEdm } from '@app/odata';
import { CommonService } from '@app/shared/services/common.service';
import { OptiaUserAccountService } from '@app/shared/services/optia-user-account.service';
import { MsalService } from '@azure/msal-angular';
import { fromEvent, debounceTime, distinctUntilChanged, tap, merge } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { hasPermission } from '@app/shared/utils/user-permissions.extensions';
import { NotificationService } from '@app/shared/services/notification.service';
import { AnnouncementsDataSource } from '@app/shared/data-sources/announcements.data-source';
import { UserAnnouncementsService } from '@app/shared/services/user-announcements.service';
import { NotificationPersonStatus, UserAnnouncementTypesEnum } from '@app/shared/lookups/enums';
import { PersonAnnouncementInfo } from '@app/shared/models/person-announcement-info';
import { AnnouncementDetailsDialogComponent } from '../announcement-details-dialog/announcement-details-dialog.component';

@Component({
  selector: 'app-announcements-dialog',
  templateUrl: './announcements-dialog.component.html',
  styleUrls: ['./announcements-dialog.component.scss']
})
export class AnnouncementsDialogComponent {

  public loggedInUser: PersonEdm;
  public annsList: PersonAnnouncementInfo[] = new Array();

  public typeFilterSelection?: number = -1;

  public displayedColumns: string[] = ['type', 'agencyName', 'subject', 'content', 'dateSent', 'expiryDate', 'status'];
  public userAnnouncementTypesEnum = UserAnnouncementTypesEnum;
  public userAnnouncementTypes = this.commonService.createArrayFromEnum(this.userAnnouncementTypesEnum);

  public userAnnouncementStatusesEnum = NotificationPersonStatus;

  public announcementsDataSource!: AnnouncementsDataSource;
  public gridDataSource = new MatTableDataSource<PersonAnnouncementInfo>([]);


  public totalTasks: number = 0;

  public sortChanges: Sort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filterInput') filterInput!: ElementRef;
  @ViewChild('jumpToPageInput') jumpToPageInput!: ElementRef;

  constructor(
    private authService: MsalService,
    private annsService: UserAnnouncementsService,
    private commonService: CommonService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public currentDialogRef: MatDialogRef<AnnouncementsDialogComponent>,
    private optiaUserService: OptiaUserAccountService,
    private notifications: NotificationService,
    private announcementService: UserAnnouncementsService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.announcementsDataSource = new AnnouncementsDataSource(this.annsService);
    this.announcementsDataSource.totalItemsCount$.subscribe((res) => {
      if (this.paginator) {
        this.paginator.length = res;
      } else {
        this.totalTasks = res;
      }
    });

    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount !== null) {
      this.optiaUserService.retrieveOptiaUser(activeAccount.localAccountId).subscribe(res => {
        this.loggedInUser = res;

        this.loadAnnsList();
      });
    }
  }

  ngAfterViewInit() {

    if (this.totalTasks > 0 && this.paginator.length === 0) {
      this.paginator.length = this.totalTasks;
    }

    // server-side search
    fromEvent(this.filterInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadAnnsList();
        })
      )
      .subscribe();

    // table jump-to custom pagination
    fromEvent(this.jumpToPageInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.jumpToPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    this.sort.sortChange.subscribe((sort: Sort) => {
      this.paginator.pageIndex = 0;
      this.sortChanges = sort;
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.loadAnnsList())
      )
      .subscribe();
  }

  loadAnnsList() {
    let filterText = '';
    if (this.filterInput) {
      filterText = this.filterInput.nativeElement.value;
    }

    let sortColumn = 'dateSent';
    let sortDirection = 'desc';
    if (this.sort) {
      sortColumn = this.sort.active;
      sortDirection = this.sort.direction;
    }

    let pageNumber = 0;
    let pageSize = 10;
    if (this.paginator) {
      pageNumber = this.paginator.pageIndex;
      pageSize = this.paginator.pageSize;
    }

    this.announcementsDataSource.searchMyAnnouncements(
      this.loggedInUser.personGuid!,
      filterText,
      sortColumn,
      sortDirection,
      pageNumber,
      pageSize,
      this.typeFilterSelection
    );

    this.announcementsDataSource.announcementsSubject.subscribe(data => {

      this.annsList = data;
      this.gridDataSource.data = data;
    });

  }

  showAnnouncementDetails(announcement: PersonAnnouncementInfo) {
    
    const dialogRef = this.dialog.open(AnnouncementDetailsDialogComponent, {
      data: {
        announcement: announcement,
        loggedInUser: this.loggedInUser
      },
      width: '35rem',
      maxHeight: '40rem'
    });
        
    dialogRef.afterClosed().subscribe(res => {

      if(announcement.status === NotificationPersonStatus.Unread) {
        announcement.dateRead = new Date;
        announcement.status = NotificationPersonStatus.Read;
  
        this.announcementService.markAnItemAsRead(announcement).subscribe();
      }
    });
  }

  onSelectFilterChanged() {
    this.paginator.pageIndex = 0;
   
    this.loadAnnsList();
  }

  isHeaderItemChanged(event: MatCheckboxChange) {
  }

  clearFilterInput(searchField: HTMLInputElement): void {
    searchField.value = '';
    this.loadAnnsList();
  }

  jumpToPage() {
    let requestedPage = this.jumpToPageInput.nativeElement.value;
    let totalPages = Math.ceil(this.paginator.length / this.paginator.pageSize);
    if (requestedPage <= totalPages) {
      // substructing here because the paginator -> pageIndex is zero based
      this.paginator.pageIndex = requestedPage - 1;
      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    }
  }

  btnClose_click() {

    this.currentDialogRef.close(null);
  }
}
