import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './DataDomain/EFEntities/accountedm.entity';
import { AgencyEdm } from './DataDomain/EFEntities/Agency/agencyedm.entity';
import { CreditAssignmentEdm } from './DataDomain/EFEntities/Credit/creditassignmentedm.entity';
import { CreditRequestEdm } from './DataDomain/EFEntities/Customer/creditrequestedm.entity';
import { CustomerEdm } from './DataDomain/EFEntities/Customer/customeredm.entity';
import { CustomerAgencyEdm } from './DataDomain/EFEntities/Customer/customeragencyedm.entity';
import { CustomerAgencyDocumentEdm } from './DataDomain/EFEntities/Customer/customeragencydocumentedm.entity';
import { CustomerAgencyPersonEdm } from './DataDomain/EFEntities/Customer/customeragencypersonedm.entity';
//#endregion

@Injectable()
export class CustomerAgenciesService extends ODataEntitySetService<CustomerAgencyEdm> {
  constructor(client: ODataClient) {
    super(client, 'CustomerAgencies', 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm');
  }
  //#region ODataApiGen Actions
  public verifyCustomerAgency(key: EntityKey<CustomerAgencyEdm>): ODataActionResource<null, boolean> { 
    return this.entity(key).action<null, boolean>('OptiaApi.VerifyCustomerAgency');
  }
  public callVerifyCustomerAgency(key: EntityKey<CustomerAgencyEdm>, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<null, boolean>(
      null, 
      this.verifyCustomerAgency(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public accounts(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<AccountEdm> { 
    return this.entity(key).navigationProperty<AccountEdm>('accounts'); 
  }
  public fetchAccounts(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<AccountEdm>) {
    return this.fetchNavigationProperty<AccountEdm>(
      this.accounts(key), 
      'entities', options) as Observable<ODataEntities<AccountEdm>>;
  }
  public addAccountEdmToAccounts(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntities<AccountEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.accounts(key).reference()
      .add(target);
  }
  public removeAccountEdmFromAccounts(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountEdm>>, etag?: string} = {}): Observable<any> {
    return this.accounts(key).reference()
      .remove(target);
  }
  public agency(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<AgencyEdm> { 
    return this.entity(key).navigationProperty<AgencyEdm>('agency'); 
  }
  public fetchAgency(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<AgencyEdm>) {
    return this.fetchNavigationProperty<AgencyEdm>(
      this.agency(key), 
      'entity', options) as Observable<ODataEntity<AgencyEdm>>;
  }
  public setAgencyEdmAsAgency(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntity<AgencyEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .set(target, {etag});
  }
  public unsetAgencyEdmAsAgency(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<AgencyEdm>>, etag?: string} = {}): Observable<any> {
    return this.agency(key).reference()
      .unset({etag});
  }
  public creditAssignments(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<CreditAssignmentEdm> { 
    return this.entity(key).navigationProperty<CreditAssignmentEdm>('creditAssignments'); 
  }
  public fetchCreditAssignments(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<CreditAssignmentEdm>) {
    return this.fetchNavigationProperty<CreditAssignmentEdm>(
      this.creditAssignments(key), 
      'entities', options) as Observable<ODataEntities<CreditAssignmentEdm>>;
  }
  public addCreditAssignmentEdmToCreditAssignments(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditAssignments(key).reference()
      .add(target);
  }
  public removeCreditAssignmentEdmFromCreditAssignments(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditAssignmentEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditAssignments(key).reference()
      .remove(target);
  }
  public creditRequests(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<CreditRequestEdm> { 
    return this.entity(key).navigationProperty<CreditRequestEdm>('creditRequests'); 
  }
  public fetchCreditRequests(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<CreditRequestEdm>) {
    return this.fetchNavigationProperty<CreditRequestEdm>(
      this.creditRequests(key), 
      'entities', options) as Observable<ODataEntities<CreditRequestEdm>>;
  }
  public addCreditRequestEdmToCreditRequests(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntities<CreditRequestEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.creditRequests(key).reference()
      .add(target);
  }
  public removeCreditRequestEdmFromCreditRequests(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CreditRequestEdm>>, etag?: string} = {}): Observable<any> {
    return this.creditRequests(key).reference()
      .remove(target);
  }
  public customer(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<CustomerEdm> { 
    return this.entity(key).navigationProperty<CustomerEdm>('customer'); 
  }
  public fetchCustomer(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<CustomerEdm>) {
    return this.fetchNavigationProperty<CustomerEdm>(
      this.customer(key), 
      'entity', options) as Observable<ODataEntity<CustomerEdm>>;
  }
  public setCustomerEdmAsCustomer(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntity<CustomerEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .set(target, {etag});
  }
  public unsetCustomerEdmAsCustomer(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<CustomerEdm>>, etag?: string} = {}): Observable<any> {
    return this.customer(key).reference()
      .unset({etag});
  }
  public customerAgencyDocuments(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<CustomerAgencyDocumentEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyDocumentEdm>('customerAgencyDocuments'); 
  }
  public fetchCustomerAgencyDocuments(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyDocumentEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyDocumentEdm>(
      this.customerAgencyDocuments(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyDocumentEdm>>;
  }
  public addCustomerAgencyDocumentEdmToCustomerAgencyDocuments(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyDocumentEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencyDocuments(key).reference()
      .add(target);
  }
  public removeCustomerAgencyDocumentEdmFromCustomerAgencyDocuments(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyDocumentEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencyDocuments(key).reference()
      .remove(target);
  }
  public customerAgencyPersons(key: EntityKey<CustomerAgencyEdm>): ODataNavigationPropertyResource<CustomerAgencyPersonEdm> { 
    return this.entity(key).navigationProperty<CustomerAgencyPersonEdm>('customerAgencyPersons'); 
  }
  public fetchCustomerAgencyPersons(key: EntityKey<CustomerAgencyEdm>, options?: ODataQueryArgumentsOptions<CustomerAgencyPersonEdm>) {
    return this.fetchNavigationProperty<CustomerAgencyPersonEdm>(
      this.customerAgencyPersons(key), 
      'entities', options) as Observable<ODataEntities<CustomerAgencyPersonEdm>>;
  }
  public addCustomerAgencyPersonEdmToCustomerAgencyPersons(key: EntityKey<CustomerAgencyEdm>, target: ODataEntityResource<ODataEntities<CustomerAgencyPersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerAgencyPersons(key).reference()
      .add(target);
  }
  public removeCustomerAgencyPersonEdmFromCustomerAgencyPersons(key: EntityKey<CustomerAgencyEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerAgencyPersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerAgencyPersons(key).reference()
      .remove(target);
  }
  //#endregion
}
