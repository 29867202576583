//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountEdm } from './accountedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AccountEdmEntityConfig = {
  name: 'AccountEdm',
  keys: [{name: 'accountGuid'}],
  fields: {
    accountGuid: {type: 'Edm.Guid', nullable: false},
    customerAgencyGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16', nullable: false},
    subStatus: {type: 'Edm.Int16'},
    reference: {type: 'Edm.String'},
    tradingAsName: {type: 'Edm.String'},
    transferToPayrollDate: {type: 'Edm.DateTimeOffset'},
    isSelfBill: {type: 'Edm.Boolean'},
    selfBillOrgGuid: {type: 'Edm.Guid'},
    otherSelfBillOrgDesc: {type: 'Edm.String'},
    otherSelfBillOrgNumber: {type: 'Edm.String'},
    agreedPaymentTerms: {type: 'Edm.Int16'},
    noDaysAfter: {type: 'Edm.Int32'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    syncStatus: {type: 'Edm.Int16'},
    syncStatusDateTime: {type: 'Edm.DateTimeOffset'},
    legalDate: {type: 'Edm.DateTimeOffset'},
    creditLimit: {type: 'Edm.Decimal', scale: 'variable'},
    debt: {type: 'Edm.Decimal', scale: 'variable'},
    exposure: {type: 'Edm.Decimal', scale: 'variable'},
    exposureCap: {type: 'Edm.Decimal', scale: 'variable'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    customerAgency: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', navigation: true, referentials: [{property: 'customerAgencyGuid', referencedProperty: 'customerAgencyGuid'}]},
    accountBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.AccountBankDetailEdm', collection: true, navigation: true},
    accountPersons: {type: 'OptiaApi.DataDomain.EFEntities.AccountPersonEdm', collection: true, navigation: true},
    contracts: {type: 'OptiaApi.DataDomain.EFEntities.ContractEdm', collection: true, navigation: true},
    creditRequests: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CreditRequestEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AccountEdm>;
//#endregion