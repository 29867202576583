export enum AgencyStatusEnum {
  'Prospect' = 0,
  'Live (Active)' = 1,
  'Live (Inactive)' = 2,
  'Former (Unfinished)' = 3,
  'Former (Finished)' = 4,

}

export enum WFRoleType {
  AgencyNotification = 0,
  BackOfficeNotification = 1
}

export enum OptiaUserRoles {
  BackOfficeAdmin = 0,
  BackOfficePayrollOnboarding = 1,
  AgencyMaster = 2
}

export enum UserGroupTypesEnum {
  'Back Office' = 0,
  Agency = 1
}
export enum UserGroupTypesList {
  'Back Office' = -1,
  'Agency Standard' = 0,
  'Agency Specific' = 1
}

export enum UserGroupCategoriesEnum {
  Standard = 0,
  Specific = 1
}

export enum AgencyNotificationType {
  Agency,
  'Back Office'
}

export enum AgencyContactType {
  'Agency' = 0,
  'Payroll Account Manager' = 1,
  'Payroll Account Manager Buddy' = 2,
  'Credit Controller' = 3,
  'Credit Controller Buddy' = 4,
  'Business Development Manager' = 5
}

export enum CompanyTypeEnum {
  //LTD,//Private company limited by shares
  //PLC,//Public limited company
  //UNLTD, //Unlimited company
  //LLP, //Limited liability partnership
  //IPS, //Industrial and Provident Society
  //RC, //Royal Charter

    'private-unlimited' = "Private unlimited company",
    'ltd' = "Private limited company",
    'plc' = "Public limited company",
    'old-public-company' = "Old public company",
    'private-limited-guarant-nsc-limited-exemption' = "Private Limited Company by guarantee without share capital, use of 'Limited' exemption",
    'limited-partnership' = "Limited partnership",
    'private-limited-guarant-nsc' = "Private limited by guarantee without share capital",
    'converted-or-closed' = "Converted / closed",
    'private-unlimited-nsc' = "Private unlimited company without share capital",
    'private-limited-shares-section-30-exemption' = "Private Limited Company, use of 'Limited' exemption",
    'protected-cell-company' = "Protected cell company",
    'assurance-company' = "Assurance company",
    'oversea-company' = "Overseas company",
    'eeig-establishment' = "European Economic Interest Grouping Establishment (EEIG)",
    'icvc-securities' = "Investment company with variable capital",
    'icvc-warrant' = "Investment company with variable capital",
    'icvc-umbrella' = "Investment company with variable capital",
    'registered-society-non-jurisdictional'= "Registered society",
    'industrial-and-provident-society' = "Industrial and Provident society",
    'northern-ireland' = "Northern Ireland company",
    'northern-ireland-other' = "Credit union (Northern Ireland)",
    'llp' = "Limited liability partnership",
    'royal-charter' = "Royal charter company",
    'investment-company-with-variable-capital' = "Investment company with variable capital",
    'unregistered-company' = "Unregistered company",
    'other' = "Other company type",
    'european-public-limited-liability-company-se' = "European public limited liability company (SE)",
    'united-kingdom-societas' = "United Kingdom Societas",
    'uk-establishment' = "UK establishment company",
    'scottish-partnership' = "Scottish qualifying partnership",
    'charitable-incorporated-organisation' = "Charitable incorporated organisation",
    'scottish-charitable-incorporated-organisation' = "Scottish charitable incorporated organisation",
    'further-education-or-sixth-form-college-corporation' = "Further education or sixth form college corporation",
    'eeig' = "European Economic Interest Grouping (EEIG)",
    'ukeig' = "United Kingdom Economic Interest Grouping",
    'registered-overseas-entity' = "Overseas entity"

}


export enum CompanyHouseSummary {

    'other' = "Other",
    'private-unlimited' = "Private unlimited company",
    'ltd' = "Private limited Company",
    'plc' = "Public limited Company",
    'protected-cell-company' = "Protected Cell Company",
    'old-public-company' = "Old public company",
    'private-limited-guarant-nsc-limited-exemption' = "Private Limited Company by guarantee without share capital use of 'Limited' exemption",
    'limited-partnership' = "Limited partnership",
    'private-limited-guarant-nsc' = "Private company limited by guarantee without share capital",
    'converted-or-closed' = "Converted/closed company",
    'private-unlimited-nsc' = "Private unlimited company without share capital",
    'private-limited-shares-section-30-exemption' = "Private limited company use of 'Limited' exemption",
    'assurance-company' = "Assurance company",
    'oversea-company' = "Overseas company",
    'eeig-establishment' = "European Economic Interest Grouping Establishment (EEIG)",
    'icvc-securities' = "Investment company with variable capital",
    'icvc-warrant' = "Investment company with variable capital",
    'icvc-umbrella' = "Investment company with variable capital",
    'registered-society-non-jurisdictional'= "Registered society",
    'industrial-and-provident-society' = "Industrial and Provident Society",
    'northern-ireland' = "Northern Ireland company",
    'northern-ireland-other' = "Credit union (Northern Ireland)",
    'llp' = "Limited liability partnership",
    'royal-charter' = "Royal Charter company",
    'investment-company-with-variable-capital' = "Investment company with variable capital",
    'unregistered-company' = "Unregistered company",
    'european-public-limited-liability-company-se' = "European public limited liability company (SE)",
    'united-kingdom-societas' = "United Kingdom Societas",
    'scottish-partnership' = "Scottish qualifying partnership",
    'charitable-incorporated-organisation' = "Charitable incorporated organisation",
    'scottish-charitable-incorporated-organisation' = "Scottish charitable incorporated organisation",
    'further-education-or-sixth-form-college-corporation' = "Further education or sixth form college corporation",
    'eeig' = "European Economic Interest Grouping (EEIG)",
    'ukeig' = "United Kingdom Economic Interest Grouping",
    'uk-establishment' = "UK establishment company",
    'registered-overseas-entity' = "Overseas entity"
}


export enum OptiaEntity {
  Agency,
  Customer,
  Person,
  Worker,
  Contract,
  Assignment,
  BankDetails,
  Address,
  PhoneNumber,
  Document,
  RegEx,
  OptiaNews,
  CompaniesHouseDetails,
  Charity,
  Account,
  MIReportGroup,
  MIReportTypeValidationRules,
  MIReportType,
  CreditAssignment,
  CreditLimit,
  CreditCheck,
  WorkerType,
  UmbrellaCompany,
  UserGroup,
  Permission,
  Audit
}

export enum OptiaDocumentType {
  Logo = 0,
  'Company Letterhead / Proof of Registration' = 1,
  'Company Terms & Conditions' = 2,
  'Proof of Discharge Document' = 3,
  'Compliance Pack Document' = 4,
  'P45' = 5,
  'Starter Checklist' = 6,
  'Certificate of Incorporation' = 7,
  'VAT Certificate' = 8,
  'UTR Certificate' = 9,
  'UTR Number' = 10,
  'IR35 Determination' = 11,
  'Bulk Worker Upload' = 12,
  'Agency Upload' = 13,
  'Form Template' = 14
}

export enum PermissionGrouping {
  Dashboard,		//0
  'User Management',
  Agencies,
  Customers,
  Suppliers,
  Workers,		//5
  MI,
  Documents,
  'Optia News',
  Forms,
  'List Management' //10
}

export enum CustomerWarnings {
  "Not Found" = 1,
  Suspended,
  Reported,
  Legal
}

export enum CustomerStatusEnum {
  Unverified,
  Active,
  Inactive
}

export enum CustomerSubStatusEnum {
  Housekeep,
  Insolvent
}

export enum CustomerAgencyStatusEnum {
  Unverified,
  Active,
  Dormant
}

export enum CustomerAgencySubStatusEnum {
  Housekeep,
  Incorrect,
  Insolvent
}

export enum CustomerTypeEnum {
  LLP,
  Ltd,
  'Sole Trader',
  Partnership,
  Government,
  International,
  "Charitable Organisation"
}

export enum CompaniesHouseStatus {
  //Unknown,
  //Active,
  //Dissolved,
  //"In Liquidation"

  //None,
  Unknown,
	Active,
	Dissolved,
  "In Liquidation",
	Receivership,
	Administration,
	VoluntaryArrangement,
	ConvertedClosed,
	InsolvencyProceedings,
	Open,
	Closed,
	ClosedOn,
	Registered,
	Removed
}

export enum mismatchVerificationReasoning {
  "Typo on Company number",
  "Typo on Legal Name",
  Other
}

export enum ContractStatusEnum {
  Active,
  Dormant
}

export enum ContractSubStatusEnum {
  Housekeep,
  Incorrect,
  Insolvent
}

export enum Contract_PersonType {
  Contact,
  Invoice
}

export enum PayrollFrequency {
  Weekly,
  Monthly,
  'Weekly & Monthly'
}

export enum InvoicePreference {
  Email,
  Post,
  'Email & Post'
}

export enum AccountStatusEnum {
  Active,
  Dormant
}

export enum AccountSubStatusEnum {
  Housekeep,
  Incorrect,
  Insolvent
}

export enum AgreedPaymentTermsEnum {
  "Days After Invoice",
  "Days After End Of Month",
  "Days After End of Following Month",
  "Last Day of Following Month"
}

export enum ChangeSetType {
  Undefined,
  Create,
  Update,
  Delete,
  Information //Use this one if you just want to display some data to the user in the history/changeset details
}

export enum ChangeSetValueType {
  string,
  int,
  double,
  date
}

export enum ChangeSetStatus {
  'Awaiting Approval',
  Rejected,
  Approved
}

export enum CreditStatus {
  Pending,
  Completed
}

export enum ChangeSetEntity {
  Account,
  Address,
  Agency,
  Assignment,
  BankDetails,
  Charity, // 5
  Contract,
  Customer,
  Person,
  PhoneNumber,
  PurchaseOrder, // 10
  Supplier,
  Worker,
  WorkerType,
  CustomerAgency,
  CustomerAgencyDocument, // 15
  PersonWithContactDetails, //NOT USED
  WorkerPhoneNumber,
  WorkerAddress,
  PersonPhoneNumber,
  SupplierWorkerType, // 20
  WorkerTypeDocument,
  WorkerTypeMIGrouping,
  ContractMIGrouping,
  ContractPerson,
  Document, // 25
  PersonAddress,
  WorkerDocument,
  WorkerTypeBankDetails,
  SupplierContact,
  SupplierContactPhoneNumber, // 30
  SupplierAddress,
  SupplierBankDetails,
  SupplierAgencyDocument,
  SupplierAgency,
  WorkerTypePerson, // 35
  WorkerTypeAddress
}

export enum wfProcessType {
  Unspecified,
  ChangeSet,
  CustomerVerification,
  SelfBillOrgVerification,
  ManageCredit
  
}

export enum wfTaskType {
  Unspecified,
  OutcomesOnly,
  ChangeSetView,
  ChangeSetEdit,
  CustomerVerification,
  SelfBillOrgVerification, //5
  ManageCredit
  
}

export enum CustomerVerifiedBy {
  Experian,
  Insurer,
  Other
}

export enum DocumentModificationMode {
  Instant,
  PendingApproval
}

export enum SupplierType {
  'Umbrella Company',
  'Managed Agency'
}

export enum SupplierPersonType {
  Contact
}

export enum SupplierStatus {
  'Active (Compliant)',
  'Inactive (Do Not Use)'
}

export enum WorkerStatusEnum {
  'Not yet used',
  Active,
  Left
}

export enum WorkerTitleEnum {
  'Mr',
  'Miss',
  'Mrs',
  'Ms',
  'Dr (Male)',
  'Dr (Female)'
}

export enum WorkerTypeStatusEnum {
  'Not yet used',
  Active,
  'Leaver Pending',
  Left,
  'Supplier Change'
}

export enum WorkerTypeTypeEnum {
  PAYE,
  'Limited Company (Non-PAYE)',
  'Umbrella (Non-PAYE)',
  'Self-Employed (Non-PAYE)',
  'Limited Liability Partnership (Non-PAYE)',
  'Managed Agency (Non-PAYE)'
}

export enum WorkerTypeIR35StatusEnum {
  'N/A',
  'Undetermined',
  'Inside IR35',
  'Outside IR35'
}

export enum AddressTypeEnum {
  Home,
  Work
}

export enum WorkerTypeBankDetailType {
  Worker,
  Remittance
}

export enum WorkerTypePAYEDeclaration {
  'A. This is my first job since last 6 April and I have not been receiving taxable Jobseeker’s Allowance, Employment and Support Allowance, or taxable Incapacity Benefit, State or Occupational Pension.',
  'B. This is now my only job, but since last 6 April I have had another job, or received taxable Jobseeker’s Allowance, Employment and Support Allowance, or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.',
  'C. As well as my new job, I have another job or receive State or Occupational Pension.',
}

// shared status for the MI report groups and MI report types
export enum MIReportSharedStatus {
  Disabled,
  Enabled
}

export enum MIReportTypeFileType {
  PDF,
  XLS,
  XLSX,
  XLSTAB,
  XLSXTAB
}

export enum TransferUploadStatus {
  'In Progress',
  Completed,
  'Part Complete',
  Failed
}

export enum TransferBatchPeriod {
  Weekly,
  'Month End'
}
//BELOW IS USED FOR THE INPUT VALIDATION REPORT APPROVALS
export enum TransferMIReportTypeStatus {
  Pending,
  Rejected,
  Approved
}

export enum DataMismatchStatus {
  Pending,
  Actioned
}
export enum DataMismatchEntityType {
  Customer,
  Account,
  'Agency/Customer',
  Contract,
  Worker,
  WorkerType,
  Supplier
}

export enum DataMismatchTypeFilter {
  Account = 1,
  WorkerType = 5,
  Supplier = 6
}

export enum HistoricalReferenceType {
  Undefined,
  Customer,
  Worker,
  Supplier
}

export enum OptiaNewsStatus {
  Unread,
  Read
}

export enum CreditRequestStatus {
  Pending,
  Complete
}

export enum NotificationPersonStatus {
  Unread,
  Read
}

export enum UserAnnouncementTypesEnum {
  'New News Item',
  'New Credit Request',
  'New CustomerVerification',
  'New Changeset',
  'New SelfBill Verification',
  'New Input Validation Report'
}