//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerPhoneNumberEdm } from './workerphonenumberedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerPhoneNumberEdmEntityConfig = {
  name: 'WorkerPhoneNumberEdm',
  keys: [{name: 'workerPhoneNumberGuid'}],
  fields: {
    workerGuid: {type: 'Edm.Guid'},
    phoneNumberGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    workerPhoneNumberGuid: {type: 'Edm.Guid', nullable: false},
    phoneNumber: {type: 'OptiaApi.DataDomain.EFEntities.PhoneNumberEdm', navigation: true, referentials: [{property: 'phoneNumberGuid', referencedProperty: 'phoneNumberGuid'}]},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]}
  }
} as StructuredTypeConfig<WorkerPhoneNumberEdm>;
//#endregion