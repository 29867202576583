//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskEdm } from './wftaskedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WfTaskEdmEntityConfig = {
  name: 'WfTaskEdm',
  keys: [{name: 'wfTaskGuid'}],
  fields: {
    wfTaskGuid: {type: 'Edm.Guid', nullable: false},
    wfTaskGroupGuid: {type: 'Edm.Guid'},
    ownerGuid: {type: 'Edm.Guid'},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    detail: {type: 'Edm.String', maxLength: -1},
    taskType: {type: 'Edm.Int32'},
    processType: {type: 'Edm.Int32'},
    notifications: {type: 'Edm.Int32'},
    flags: {type: 'Edm.Int32'},
    defaultOwnerCount: {type: 'Edm.Int32'},
    script: {type: 'Edm.String', maxLength: -1},
    initScript: {type: 'Edm.String', maxLength: -1},
    wfTaskGroup: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskGroupEdm', navigation: true, referentials: [{property: 'wfTaskGroupGuid', referencedProperty: 'wfTaskGroupGuid'}]},
    wfActions: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfActionEdm', collection: true, navigation: true},
    wfOutcomes: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfOutcomeEdm', collection: true, navigation: true},
    wfRules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfRuleEdm', collection: true, navigation: true},
    wfTaskContactTypes: {type: 'OptiaApi.DataDomain.EFEntities.WfTaskContactTypeEdm', collection: true, navigation: true},
    wfTaskPermissions: {type: 'OptiaApi.DataDomain.EFEntities.WfTaskPermissionEdm', collection: true, navigation: true},
    wfTaskRoles: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskRoleEdm', collection: true, navigation: true},
    wfTaskTabs: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTabEdm', collection: true, navigation: true},
    wfTaskTemplates: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskTemplateEdm', collection: true, navigation: true},
    wfTaskWfSchedules: {type: 'OptiaApi.DataDomain.EFEntities.Workflow.WfTaskWfScheduleEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WfTaskEdm>;
//#endregion