import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NotificationEdm } from './DataDomain/EFEntities/Notify/notificationedm.entity';
import { NotificationPersonEdm } from './DataDomain/EFEntities/Notify/notificationpersonedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
//#endregion

@Injectable()
export class NotificationPersonsService extends ODataEntitySetService<NotificationPersonEdm> {
  constructor(client: ODataClient) {
    super(client, 'NotificationPersons', 'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm');
  }
  //#region ODataApiGen Actions
  public markAllRead(key: EntityKey<NotificationPersonEdm>): ODataActionResource<null, boolean> { 
    return this.entity(key).action<null, boolean>('OptiaApi.MarkAllRead');
  }
  public callMarkAllRead(key: EntityKey<NotificationPersonEdm>, options?: ODataOptions & {alias?: boolean}) {
    return this.callAction<null, boolean>(
      null, 
      this.markAllRead(key), 
      'property', options) as Observable<ODataProperty<boolean>>;
  }
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public notification(key: EntityKey<NotificationPersonEdm>): ODataNavigationPropertyResource<NotificationEdm> { 
    return this.entity(key).navigationProperty<NotificationEdm>('notification'); 
  }
  public fetchNotification(key: EntityKey<NotificationPersonEdm>, options?: ODataQueryArgumentsOptions<NotificationEdm>) {
    return this.fetchNavigationProperty<NotificationEdm>(
      this.notification(key), 
      'entity', options) as Observable<ODataEntity<NotificationEdm>>;
  }
  public setNotificationEdmAsNotification(key: EntityKey<NotificationPersonEdm>, target: ODataEntityResource<ODataEntity<NotificationEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.notification(key).reference()
      .set(target, {etag});
  }
  public unsetNotificationEdmAsNotification(key: EntityKey<NotificationPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<NotificationEdm>>, etag?: string} = {}): Observable<any> {
    return this.notification(key).reference()
      .unset({etag});
  }
  public person(key: EntityKey<NotificationPersonEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('person'); 
  }
  public fetchPerson(key: EntityKey<NotificationPersonEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.person(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsPerson(key: EntityKey<NotificationPersonEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsPerson(key: EntityKey<NotificationPersonEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.person(key).reference()
      .unset({etag});
  }
  //#endregion
}
