import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class AppOnlyNumberDirective {

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return true;
    }
    // Ensure that it is a number and stop the keypress othervise
    if (!/\d/.test(e.key))
    {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
      return false;
    }

    // Ensure that it is a positive number, if not than stop the keypress
    if(!(this.inputElement as HTMLInputElement).value &&
      (!Number.isNaN(e.key) && parseInt(e.key) <= 0)) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
    }
    
    if((this.inputElement as HTMLInputElement).value 
      && window.getSelection()
      && (window.getSelection()?.focusNode as any).className == 'col page-select'
      && window.getSelection()?.type === "Range") {
      let currentSelection = window.getSelection();
      let textAfterReplacement = (this.inputElement as HTMLInputElement).value.replace(currentSelection?.toString() as string, '')
      let finalExpression = textAfterReplacement + e.key;
      if(parseInt(finalExpression) <= 0) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
      }
    }

    return true;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    const pastedInput = event.clipboardData?.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
    if(pastedInput && parseInt(pastedInput) > 0) {
      (this.inputElement as HTMLInputElement).value = pastedInput ? pastedInput as string : '';
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    const textData = event.dataTransfer?.getData('text').replace(/\D/g, '');
    if(textData && parseInt(textData) > 0) {
      this.inputElement.focus();
      (this.inputElement as HTMLInputElement).value = textData ? textData as string : '';
    }
  }
}