//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerEdm } from './customeredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerEdmEntityConfig = {
  name: 'CustomerEdm',
  keys: [{name: 'customerGuid'}],
  fields: {
    customerGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    isCharity: {type: 'Edm.Boolean'},
    addedBy: {type: 'Edm.String'},
    dateAdded: {type: 'Edm.DateTimeOffset'},
    customerType: {type: 'Edm.Int16'},
    status: {type: 'Edm.Int16', nullable: false},
    subStatus: {type: 'Edm.Int16'},
    estimatedCreditNeeded: {type: 'Edm.Decimal', scale: 'variable'},
    companiesHouseDetailsGuid: {type: 'Edm.Guid'},
    tradingName: {type: 'Edm.String'},
    exactLegalNamePayroll: {type: 'Edm.String'},
    charityGuid: {type: 'Edm.Guid'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    internationalNumber: {type: 'Edm.String'},
    enteredCompanyNumber: {type: 'Edm.String'},
    transferredToPayrollDate: {type: 'Edm.DateTimeOffset'},
    treasurer: {type: 'Edm.String'},
    verificationComments: {type: 'Edm.String'},
    statusFlag: {type: 'Edm.String'},
    suspendedDate: {type: 'Edm.DateTimeOffset'},
    reportedForMaxExtension: {type: 'Edm.DateTimeOffset'},
    reportedForCashflow: {type: 'Edm.DateTimeOffset'},
    reportedForInsolvency: {type: 'Edm.DateTimeOffset'},
    reportedForLegal: {type: 'Edm.DateTimeOffset'},
    reportedForPaymentPlan: {type: 'Edm.DateTimeOffset'},
    creditLimit: {type: 'Edm.Decimal', scale: 'variable'},
    creditLimitReviewDate: {type: 'Edm.DateTimeOffset'},
    distributedCredit: {type: 'Edm.Decimal', scale: 'variable'},
    undistributedCredit: {type: 'Edm.Decimal', scale: 'variable'},
    debt: {type: 'Edm.Decimal', scale: 'variable'},
    exposure: {type: 'Edm.Decimal', scale: 'variable'},
    creditSource: {type: 'Edm.String'},
    experianResult: {type: 'Edm.Decimal', scale: 'variable'},
    experianDate: {type: 'Edm.DateTimeOffset'},
    insurerResult: {type: 'Edm.Decimal', scale: 'variable'},
    insurerDate: {type: 'Edm.DateTimeOffset'},
    nextReviewDate: {type: 'Edm.DateTimeOffset'},
    charity: {type: 'OptiaApi.DataDomain.EFEntities.CharityEdm', navigation: true, referentials: [{property: 'charityGuid', referencedProperty: 'charityGuid'}]},
    companiesHouseDetail: {type: 'OptiaApi.DataDomain.EFEntities.CompaniesHouseDetailEdm', navigation: true, referentials: [{property: 'companiesHouseDetailsGuid', referencedProperty: 'companiesHouseDetailsGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    creditChecks: {type: 'OptiaApi.DataDomain.EFEntities.Credit.CreditCheckEdm', collection: true, navigation: true},
    customerAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAddressEdm', collection: true, navigation: true},
    customerAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', collection: true, navigation: true},
    customerBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.CustomerBankDetailEdm', collection: true, navigation: true},
    customerDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerDocumentEdm', collection: true, navigation: true},
    customerPersons: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerPersonEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<CustomerEdm>;
//#endregion