//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RequestsDashboardEdm } from './requestsdashboardedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RequestsDashboardEdmEntityConfig = {
  name: 'RequestsDashboardEdm',
  keys: [{name: 'wfActionGuid'}],
  fields: {
    wfActionGuid: {type: 'Edm.Guid', nullable: false},
    dateRequested: {type: 'Edm.DateTimeOffset'},
    requestType: {type: 'Edm.String'},
    agency: {type: 'Edm.String'},
    entityID: {type: 'Edm.Guid'},
    entity: {type: 'Edm.String'},
    status: {type: 'Edm.Int32'},
    rejectReason: {type: 'Edm.String'},
    comments: {type: 'Edm.String'},
    createdBy: {type: 'Edm.Guid'},
    entityType: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<RequestsDashboardEdm>;
//#endregion