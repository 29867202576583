import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserInformationClaims } from './../../../shared/types/user-information-claims';
import { OptiaUserAccountService } from '@app/shared/services/optia-user-account.service';
import { PersonEdm } from '@app/odata';
import { UserTypes } from '@app/manage/models/user-types';
import { hasPermission } from '../../../shared/utils/user-permissions.extensions';
import { NotificationService } from '@app/shared/services/notification.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  welcomeMessage = '';
  userFullName = '';

  public loggedInUser: PersonEdm;
  
  public canSeeRequestsApprovalWidget: boolean = false;
  public canSeeIVRApprovalWidget: boolean = false;
  public canSeeMyAllRequestsCard: boolean = false;
  public canViewOptiaNews: boolean = false;
  
  public allMyRequestsCardTitle = 'Requests';

  constructor(private authService: MsalService,
    private optiaUserService: OptiaUserAccountService,
    private notifications: NotificationService) {

    let dashboardComponentRef = this;
    this.notifications.getNotifications().subscribe({
      next(notif) {
        if(notif.text === 'requests_card_only_my') {
          dashboardComponentRef.allMyRequestsCardTitle = 'My Requests';
        } else {
          dashboardComponentRef.allMyRequestsCardTitle = 'All Requests';
        }
      },
    });
  }

  ngOnInit(): void {

    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount !== null) {
      this.optiaUserService.retrieveOptiaUser(activeAccount.localAccountId).subscribe(res => {
        this.loggedInUser = res;
        this.canSeeRequestsApprovalWidget = hasPermission(this.loggedInUser, '_readCustomerContractEditTasks') 
        || hasPermission(this.loggedInUser, '_readAllCustomerContractEditTasks') 
        || hasPermission(this.loggedInUser, '_readCustomerAgencyEditTasks') 
        || hasPermission(this.loggedInUser, '_readAllCustomerAgencyEditTasks')
        || hasPermission(this.loggedInUser, '_readCustomerEditStatusTasks')
        || hasPermission(this.loggedInUser, '_readCustomerVerificationTasks')
        || hasPermission(this.loggedInUser, '_readWorkerEditTasks')
        || hasPermission(this.loggedInUser, '_readAllWorkerEditTasks');
        this.canSeeIVRApprovalWidget = (this.loggedInUser.userType == UserTypes.Agency && this.loggedInUser.ivrApprover == true);
        this.canViewOptiaNews = hasPermission(this.loggedInUser, '_readnewsfeed');
        this.canSeeMyAllRequestsCard = hasPermission(this.loggedInUser, '_readmyrequests') 
          || hasPermission(this.loggedInUser, '_readallrequests');

        if(hasPermission(this.loggedInUser, '_readmyrequests')) {
          this.allMyRequestsCardTitle = 'My ' + this.allMyRequestsCardTitle;
        } else if (hasPermission(this.loggedInUser, '_readallrequests')) {
          this.allMyRequestsCardTitle = 'All ' + this.allMyRequestsCardTitle;
        }
      });
    }

    this.setUserInfo();

    let timeNow = new Date().getHours();

    switch (true) {
      case (timeNow < 12):
        this.welcomeMessage = 'Good Morning';
        break;
      case (timeNow >= 12 && timeNow < 18):
        this.welcomeMessage = 'Good Afternoon';
        break;
      case (timeNow >= 18):
        this.welcomeMessage = 'Good Evening';
        break;
      default:
        this.welcomeMessage = 'Welcome to Optia';
        break;
    }
  }

  setUserInfo() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount !== null) {
      let userInfo = (activeAccount?.idTokenClaims as UserInformationClaims);
      this.userFullName = userInfo.given_name + ' ' + userInfo.family_name;
    }
  }
}
