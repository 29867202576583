//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PermissionEdm } from './permissionedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PermissionEdmEntityConfig = {
  name: 'PermissionEdm',
  keys: [{name: 'permissionGuid'}],
  fields: {
    permissionGuid: {type: 'Edm.Guid', nullable: false},
    description: {type: 'Edm.String'},
    tag: {type: 'Edm.String'},
    active: {type: 'Edm.Boolean'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    type: {type: 'Edm.Int32'},
    grouping: {type: 'Edm.Int32'},
    action: {type: 'Edm.Int32'},
    gridDescription: {type: 'Edm.String'},
    groupSort: {type: 'Edm.Int32'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    userGroupPermissions: {type: 'OptiaApi.DataDomain.EFEntities.Admin.UserGroupPermissionEdm', collection: true, navigation: true},
    wfTaskPermissions: {type: 'OptiaApi.DataDomain.EFEntities.WfTaskPermissionEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<PermissionEdm>;
//#endregion