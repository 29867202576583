export const environment = {
  production: false,
  Environment: 'dev',
  URL: 'https://www.optia.dev.rgsit.cloud',
  API: 'https://api.optia.dev.rgsit.cloud/odata',
  B2CDomain: 'optia2023',
  B2CTenantId: '0728ad9a-0246-4729-a63f-f96e0daee97b',
  ClientId: '0c3fb10f-5055-4508-b98f-03faafb8ca78',
  BackendAppIdentifier: 'api',
  ipAPI: 'https://api.ipify.org?format=json',
  SentryDns: 'https://5f5129b820ecf5409ede0323c698d954@o4508609265532928.ingest.de.sentry.io/4508795656470608'
};
