import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ChangeSetEdm, ChangeSetGroupEdm, ChangeSetLineEdm, PersonEdm, RefDataItemEdm, WfInstanceEdm } from '@app/odata';
import { ChangeSetEntity, ChangeSetStatus, ChangeSetType, ChangeSetValueType, wfProcessType } from '../lookups/enums';
import { UserTypes } from '@app/manage/models/user-types';
import { WorkflowService } from './workflow.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { RequestsDashboardEdm } from '../requestsdashboardedm.entity';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private workflowService: WorkflowService,
    private dialog: MatDialog,
    private router: Router
    // public currentDialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) { }

  createArrayFromEnum(e: any) {
    return Array.from(
      Object.keys(e).filter(k => isNaN(Number(k))),
      r => {
        return { text: r, value: e[r] };
      }
    );
  }

  formatDate(myDate?: Date): string {
    return this.datePipe.transform(myDate, "dd-MM-yyyy") ?? '';
  }

  openSnackBar(message: string, length: number, styleClass: string) {
    this.snackBar.open(message, '', { duration: length, panelClass: [styleClass] });
  }

  formatCountryCode(countryCodes: RefDataItemEdm[], id?: string): string {
    let code = '';
    if (countryCodes && id) {
      code = countryCodes.find(c => c.value == id)?.description ?? '';
    }
    return code;
  }

  formatCountry(countries: RefDataItemEdm[], id?: string): string {
    let country = '';
    if (countries && id) {
      country = countries.find(c => c.value == id)?.description ?? '';
    }
    return country
  }

  formatPhoneType(phoneTypes: RefDataItemEdm[], val?: number): string {
    let typeDesc = phoneTypes.find(t => t.value == val)?.description;
    return typeDesc ?? '';
  }

  formatSortCode(dbSortCode: string): string {
    if (!dbSortCode || dbSortCode === '') {
      return '';
    }
    let sortCode = dbSortCode;
    sortCode = dbSortCode.slice(0, 2) + '-' + dbSortCode.slice(2, 4) + '-' + dbSortCode.slice(4);
    return sortCode;
  }

  formatVATNumber(dbVatNumber: string): string {
    let sortCode = dbVatNumber;
    sortCode = dbVatNumber.slice(0, 2) + ' ' + dbVatNumber.slice(2, 4) + ' ' + dbVatNumber.slice(4);
    return sortCode;
  }

  createChangeSetGroup(person: PersonEdm, agencyGuid: string | undefined, description: string, entity: ChangeSetEntity, entityKey: string, link: string): ChangeSetGroupEdm {
    let csg: ChangeSetGroupEdm = {
      requestDate: new Date,
      entity: entity,
      entityKey: entityKey,
      entityLink: link,
      status: person.userType == UserTypes.BackOffice ? ChangeSetStatus['Approved'] : ChangeSetStatus['Awaiting Approval'],
      agencyGuid: agencyGuid,
      description: description
    }

    return csg;
  }

  createChangeSet(person: PersonEdm, agencyGuid: string | undefined, changeSetType: ChangeSetType, entity: ChangeSetEntity, changeSetGroupGuid: string, entityKey?: string): ChangeSetEdm {
    let cr: ChangeSetEdm = {
      status: person.userType == UserTypes.BackOffice ? ChangeSetStatus['Approved'] : ChangeSetStatus['Awaiting Approval'],
      personGuid: person.personGuid!,
      changeSetGroupGuid: changeSetGroupGuid,
      requestDate: new Date(),
      changeType: changeSetType,
      entity: entity,
      agencyGuid: agencyGuid
    }

    if (entityKey) cr.entityKey = entityKey;

    return cr;
  }

  createChangeSetLine(changeSetGuid: string, column: string, type: ChangeSetValueType, value: any): ChangeSetLineEdm {
    let csl: ChangeSetLineEdm = {
      changeSetGuid: changeSetGuid,
      columnName: column,
      charNvalue: type == ChangeSetValueType.string ? value : null,
      intValue: type == ChangeSetValueType.int ? value : null,
      doubleValue: type == ChangeSetValueType.double ? value : null,
      dateValue: type == ChangeSetValueType.date ? value : null,
      //createdOn: new Date()
    }
    return csl;
  }

  startWorkflowProcess(processType: wfProcessType, ownerGuid: string, agencyGuid: string | undefined, entity: ChangeSetEntity | undefined) {
    return this.workflowService.createWorkflowInstance(processType, ownerGuid, agencyGuid, entity);
  }

  handleError(err: any) {
    this.openSnackBar('The backend operation has failed. Please, contact the support team or system administrator.', 4000, 'error-snackbar');
  }

  /**
    * Marks all controls in a form group as touched
    * @param formGroup - The form group to touch
    */
  markFormGroupTouched(formGroup: FormGroup) {
    formGroup.markAllAsTouched();

    (<any>Object).values(formGroup.controls).forEach((control: FormGroup<any>) => {
      control.markAsDirty();
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }

    });
  }

  validateMin(num: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if ((control?.value.length) == 0)
        return { 'required': true }

      if ((control?.value.length) < num)
        return { min: true }

      // if ((control?.value.length) > num)
      //   return { min: true }

      return null;
    };
  }

  validateMinMax(min: number, max: number, required: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      var count = control?.value.match(/\d/g)?.length || 0

      if ((count) == 0 && required)
        return { 'required': true }

      if ((count) < min)
        return { min: true }

      if ((count) > max)
        return { min: true }

      return null;
    };
  }


  confirmationDialog(messagge: string, okMsg = 'Yes', cancelMsg = 'No', hideCancel = false) {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: messagge,
        buttonText: {
          ok: okMsg,
          cancel: cancelMsg
        },
        hideCancel: hideCancel
      },
      width: '30rem'
    });
  }


  rejectReasonDialog(actionInfo: RequestsDashboardEdm, okMsg = 'Ok') {

    let message: string = 'no reason provided';

    /*if(actionInfo.rejectReason) {
      message += actionInfo.rejectReason;
    }*/

    if (actionInfo.comments) {
      message = actionInfo.comments;
    }

    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: okMsg
        },
        hideCancel: true
      },
      width: '30rem'
    });
  }


  infoDialog(messagge: string, okMsg = 'Ok') {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: messagge,
        buttonText: {
          ok: okMsg
        },
        hideCancel: true
      },
      width: '30rem'
    });
  }


  trueCasingCompanyName(legalname: string) {

    if (legalname) {
      const words = legalname.split(" ");

      for (let i = 0; i < words.length; i++) {
        let toLowerVal = words[i].toLowerCase();
        words[i] = toLowerVal;
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(" ");
    }
    else return legalname;

  }

  getAgencyStatus(type: number) {
    //return AgencyStatusEnum[type];
    let statusName = ''
    switch (type) {
      case 0:
        statusName = 'Prospect';
        break;
      case 1:
        statusName = 'Active';
        break;
      case 2:
        statusName = 'Inactive';
        break;
      case 3:
        statusName = 'Unfinished';
        break;
      case 4:
        statusName = 'Finished';
        break;

    }
    return statusName;
  }


  getAgencyStatusClass(type: number) {
    let className = ''
    switch (type) {
      case 0:
        className = 'agency-status-Prospect';
        break;
      case 1:
        className = 'agency-status-live';
        break;
      case 2:
        className = 'agency-status-live-incative';
        break;
      case 3:
        className = 'agency-status-left-unfinished';
        break;
      case 4:
        className = 'agency-status-left-finished';
        break;

    }
    return className;
  }


  stringify(obj: any) {
    let cache: any = [];
    let str = JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {

      })
    })
  }

  camelToSentence(camelCaseStr: string): string {
    let sentenceCaseStr = '';

    for (let i = 0; i < camelCaseStr.length; i++) {
      const char = camelCaseStr[i];
      if (i === 0) {
        // Capitalize the first character
        sentenceCaseStr += char.toUpperCase();
      } else if (char === char.toUpperCase()) {
        // Add a space before each capital letter
        sentenceCaseStr += ' ' + char;
      } else {
        // Append the character as it is
        sentenceCaseStr += char;
      }
    }

    return sentenceCaseStr;
  }

  callIPAddressAPI(): Promise<string> {
    return this.getIpAddress()
      .then((data: any) => data.ip)
      .catch((error: any) => {
        console.error('Error fetching IP address', error);
        return '';
      });
  }

  getIpAddress(): Promise<any> {
    return this.http.get(environment.ipAPI).toPromise();
  }
}
