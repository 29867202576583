//#region ODataApiGen Imports
export * from './OptiaApi/DataDomain/EFEntities/documentdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/workerdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/accountedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/accountbankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/accountpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/addressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/agencymtdcalendaredm.entity';
export * from './OptiaApi/DataDomain/EFEntities/agencyreportingcombinationedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/assignmentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/bankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/charityedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailsaddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailspersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/contractedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/contractpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/customerbankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/departmentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/documentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/phonenumberedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/purchaseorderedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/wftaskcontacttypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/wftaskpermissionedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencydetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyaddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencybankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencydocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencypersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencypersonwfroleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyworkeredm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Agency/tradingstyleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/agencypermissiondetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Admin/backofficepermissiondetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergroupdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Admin/auditedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetgroupedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetlineedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/datamismatchedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/historicalreferenceedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/permissionedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/personusergroupedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/regexedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergroupedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergrouppermissionedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/inputvalidationtaskedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportcombinationedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportapprovaldetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportgroupswithtypeedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferlogdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/contractreportinglabeledm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportgroupedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportgrouppersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypepersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypevalidationruleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportingcategoryedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportinglabeledm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferagencyedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transfermireporttypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferlogedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferrevisionedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditassignmentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditcheckedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditlimitedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencydetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Customer/creditrequestedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeredm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeraddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencyedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencydocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencypersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerdocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewsdetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationtemplateedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewsdocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewspersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Person/persondetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Person/personedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Person/personaddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Person/persondepartmentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Person/personphonenumberedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/RefData/refdataitemedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/RefData/refdatatypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierworkertypedetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieredm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieraddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieragencyedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierbankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierdocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierworkertypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/outstandingactionedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfactionedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfinstanceedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfoutcomeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprerequisiteedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprocessedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprocesswfscheduleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfroleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfruleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfscheduleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskgroupedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskroleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftasktabedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftasktemplateedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskwfscheduleedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftemplateedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfactionoutstandingedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/requestsdashboardedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypedetailedm.complex';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workeredm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workeraddressedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerbankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerdocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerpersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerphonenumberedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypeedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypebankdetailedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypedocumentedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypepersonedm.entity';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypereportinglabeledm.entity';
export * from './OptiaApi/ServiceLayer/DTOs/optianewsdto.complex';
export * from './OptiaApi/ServiceLayer/DTOs/loggedinuserdto.entity';
export * from './OptiaApi/ServiceLayer/DTOs/permissiondto.entity';
export * from './OptiaApi/ServiceLayer/DTOs/setpassworddto.entity';
export * from './OptiaApi/optianewsdocumentdto.complex';
export * from './OptiaApi/documentdto.complex';
export * from './OptiaApi/DataDomain/EFEntities/documentdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/workerdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/accountedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/accountbankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/accountpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/addressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/agencymtdcalendaredm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/agencyreportingcombinationedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/assignmentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/bankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/charityedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailsaddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/companieshousedetailspersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/contractedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/contractpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/customerbankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/departmentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/documentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/phonenumberedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/purchaseorderedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/wftaskcontacttypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/wftaskpermissionedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencydetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyaddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencybankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencydocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencypersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencypersonwfroleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyreportingcategoryedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/agencyworkeredm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Agency/tradingstyleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/agencypermissiondetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/backofficepermissiondetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergroupdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/auditedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetgroupedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/changesetlineedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/datamismatchedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/historicalreferenceedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/permissionedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/personusergroupedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/regexedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergroupedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Admin/usergrouppermissionedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/inputvalidationtaskedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportcombinationedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportapprovaldetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportgroupswithtypeedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferlogdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/contractreportinglabeledm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportgroupedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireportgrouppersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypepersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/mireporttypevalidationruleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportingcategoryedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/reportinglabeledm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferagencyedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transfermireporttypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferlogedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Reporting/transferrevisionedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditassignmentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditcheckedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Credit/creditlimitedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencydetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/creditrequestedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeredm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeraddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencyedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencydocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customeragencypersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerdocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Customer/customerpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewsdetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/notificationtemplateedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewsdocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Notify/optianewspersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Person/persondetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Person/personedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Person/personaddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Person/persondepartmentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Person/personphonenumberedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/RefData/refdataitemedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/RefData/refdatatypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierworkertypedetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieredm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieraddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplieragencyedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierbankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierdocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Supplier/supplierworkertypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/outstandingactionedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfactionedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfinstanceedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfoutcomeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprerequisiteedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprocessedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfprocesswfscheduleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfroleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfruleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfscheduleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskgroupedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskroleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftasktabedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftasktemplateedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftaskwfscheduleedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wftemplateedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/wfactionoutstandingedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Workflow/requestsdashboardedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypedetailedm.complex.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workeredm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workeraddressedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerbankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerdocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerpersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workerphonenumberedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypeedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypebankdetailedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypedocumentedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypepersonedm.entity.config';
export * from './OptiaApi/DataDomain/EFEntities/Worker/workertypereportinglabeledm.entity.config';
export * from './OptiaApi/ServiceLayer/DTOs/optianewsdto.complex.config';
export * from './OptiaApi/ServiceLayer/DTOs/loggedinuserdto.entity.config';
export * from './OptiaApi/ServiceLayer/DTOs/permissiondto.entity.config';
export * from './OptiaApi/ServiceLayer/DTOs/setpassworddto.entity.config';
export * from './OptiaApi/optianewsdocumentdto.complex.config';
export * from './OptiaApi/documentdto.complex.config';
export * from './OptiaApi/container.service';
export * from './OptiaApi/accounts.service';
export * from './OptiaApi/accountbankdetails.service';
export * from './OptiaApi/accountpersons.service';
export * from './OptiaApi/addresses.service';
export * from './OptiaApi/agencies.service';
export * from './OptiaApi/agencyaddresses.service';
export * from './OptiaApi/agencybankdetails.service';
export * from './OptiaApi/agencydocuments.service';
export * from './OptiaApi/agencymtdcalendars.service';
export * from './OptiaApi/agencypersons.service';
export * from './OptiaApi/agencypersonwfroles.service';
export * from './OptiaApi/agencyreportingcategories.service';
export * from './OptiaApi/agencyreportingcombinations.service';
export * from './OptiaApi/agencyworkers.service';
export * from './OptiaApi/assignments.service';
export * from './OptiaApi/audits.service';
export * from './OptiaApi/bankdetails.service';
export * from './OptiaApi/changesets.service';
export * from './OptiaApi/changesetgroups.service';
export * from './OptiaApi/changesetlines.service';
export * from './OptiaApi/charities.service';
export * from './OptiaApi/companieshousedetails.service';
export * from './OptiaApi/companieshousedetailsaddresses.service';
export * from './OptiaApi/companieshousedetailspersons.service';
export * from './OptiaApi/contracts.service';
export * from './OptiaApi/contractpersons.service';
export * from './OptiaApi/contractreportinglabels.service';
export * from './OptiaApi/creditassignments.service';
export * from './OptiaApi/creditchecks.service';
export * from './OptiaApi/creditlimits.service';
export * from './OptiaApi/creditrequests.service';
export * from './OptiaApi/customers.service';
export * from './OptiaApi/customeraddresses.service';
export * from './OptiaApi/customeragencies.service';
export * from './OptiaApi/customeragencydocuments.service';
export * from './OptiaApi/customeragencypersons.service';
export * from './OptiaApi/customerbankdetails.service';
export * from './OptiaApi/customerdocuments.service';
export * from './OptiaApi/customerpersons.service';
export * from './OptiaApi/datamismatches.service';
export * from './OptiaApi/departments.service';
export * from './OptiaApi/documents.service';
export * from './OptiaApi/historicalreferences.service';
export * from './OptiaApi/mireportgroups.service';
export * from './OptiaApi/mireportgrouppersons.service';
export * from './OptiaApi/mireporttypes.service';
export * from './OptiaApi/mireporttypepersons.service';
export * from './OptiaApi/mireporttypevalidationrules.service';
export * from './OptiaApi/notifications.service';
export * from './OptiaApi/notificationpersons.service';
export * from './OptiaApi/notificationtemplates.service';
export * from './OptiaApi/optianews.service';
export * from './OptiaApi/optianewsdocuments.service';
export * from './OptiaApi/optianewspersons.service';
export * from './OptiaApi/permissions.service';
export * from './OptiaApi/people.service';
export * from './OptiaApi/personaddresses.service';
export * from './OptiaApi/persondepartments.service';
export * from './OptiaApi/personphonenumbers.service';
export * from './OptiaApi/personusergroups.service';
export * from './OptiaApi/phonenumbers.service';
export * from './OptiaApi/purchaseorders.service';
export * from './OptiaApi/refdataitems.service';
export * from './OptiaApi/refdatatypes.service';
export * from './OptiaApi/regexes.service';
export * from './OptiaApi/reportingcategories.service';
export * from './OptiaApi/reportinglabels.service';
export * from './OptiaApi/suppliers.service';
export * from './OptiaApi/supplieraddresses.service';
export * from './OptiaApi/supplieragencies.service';
export * from './OptiaApi/supplierbankdetails.service';
export * from './OptiaApi/supplierdocuments.service';
export * from './OptiaApi/supplierpersons.service';
export * from './OptiaApi/supplierworkertypes.service';
export * from './OptiaApi/tradingstyles.service';
export * from './OptiaApi/transfers.service';
export * from './OptiaApi/transferagencies.service';
export * from './OptiaApi/transfermireporttypes.service';
export * from './OptiaApi/transferlogs.service';
export * from './OptiaApi/transferrevisions.service';
export * from './OptiaApi/usergroups.service';
export * from './OptiaApi/usergrouppermissions.service';
export * from './OptiaApi/wfactions.service';
export * from './OptiaApi/wfinstances.service';
export * from './OptiaApi/wfoutcomes.service';
export * from './OptiaApi/wfprerequisites.service';
export * from './OptiaApi/wfprocesses.service';
export * from './OptiaApi/wfprocesswfschedules.service';
export * from './OptiaApi/wfroles.service';
export * from './OptiaApi/wfrules.service';
export * from './OptiaApi/wfschedules.service';
export * from './OptiaApi/wftasks.service';
export * from './OptiaApi/wftaskcontacttypes.service';
export * from './OptiaApi/wftaskgroups.service';
export * from './OptiaApi/wftaskpermissions.service';
export * from './OptiaApi/wftaskroles.service';
export * from './OptiaApi/wftasktabs.service';
export * from './OptiaApi/wftasktemplates.service';
export * from './OptiaApi/wftaskwfschedules.service';
export * from './OptiaApi/wftemplates.service';
export * from './OptiaApi/workers.service';
export * from './OptiaApi/workeraddresses.service';
export * from './OptiaApi/workerbankdetails.service';
export * from './OptiaApi/workerdocuments.service';
export * from './OptiaApi/workerpersons.service';
export * from './OptiaApi/workerphonenumbers.service';
export * from './OptiaApi/workertypes.service';
export * from './OptiaApi/workertypebankdetails.service';
export * from './OptiaApi/workertypedocuments.service';
export * from './OptiaApi/workertypepersons.service';
export * from './OptiaApi/workertypereportinglabels.service';
export * from './OptiaApi/loggedinuserdto.service';
export * from './OptiaApi/permissiondto.service';
export * from './OptiaApi/setpassworddto.service';
export * from './OptiaApi/wfactionoutstandingedm.service';
export * from './OptiaApi/actionsbypersonguid.service';
export * from './OptiaApi/requestsdashboardedm.service';
export * from './OptiaApi/dashboardrequestactionsbypersonguid.service';
export * from './OptiaApi/accounts.service.config';
export * from './OptiaApi/accountbankdetails.service.config';
export * from './OptiaApi/accountpersons.service.config';
export * from './OptiaApi/addresses.service.config';
export * from './OptiaApi/agencies.service.config';
export * from './OptiaApi/agencyaddresses.service.config';
export * from './OptiaApi/agencybankdetails.service.config';
export * from './OptiaApi/agencydocuments.service.config';
export * from './OptiaApi/agencymtdcalendars.service.config';
export * from './OptiaApi/agencypersons.service.config';
export * from './OptiaApi/agencypersonwfroles.service.config';
export * from './OptiaApi/agencyreportingcategories.service.config';
export * from './OptiaApi/agencyreportingcombinations.service.config';
export * from './OptiaApi/agencyworkers.service.config';
export * from './OptiaApi/assignments.service.config';
export * from './OptiaApi/audits.service.config';
export * from './OptiaApi/bankdetails.service.config';
export * from './OptiaApi/changesets.service.config';
export * from './OptiaApi/changesetgroups.service.config';
export * from './OptiaApi/changesetlines.service.config';
export * from './OptiaApi/charities.service.config';
export * from './OptiaApi/companieshousedetails.service.config';
export * from './OptiaApi/companieshousedetailsaddresses.service.config';
export * from './OptiaApi/companieshousedetailspersons.service.config';
export * from './OptiaApi/contracts.service.config';
export * from './OptiaApi/contractpersons.service.config';
export * from './OptiaApi/contractreportinglabels.service.config';
export * from './OptiaApi/creditassignments.service.config';
export * from './OptiaApi/creditchecks.service.config';
export * from './OptiaApi/creditlimits.service.config';
export * from './OptiaApi/creditrequests.service.config';
export * from './OptiaApi/customers.service.config';
export * from './OptiaApi/customeraddresses.service.config';
export * from './OptiaApi/customeragencies.service.config';
export * from './OptiaApi/customeragencydocuments.service.config';
export * from './OptiaApi/customeragencypersons.service.config';
export * from './OptiaApi/customerbankdetails.service.config';
export * from './OptiaApi/customerdocuments.service.config';
export * from './OptiaApi/customerpersons.service.config';
export * from './OptiaApi/datamismatches.service.config';
export * from './OptiaApi/departments.service.config';
export * from './OptiaApi/documents.service.config';
export * from './OptiaApi/historicalreferences.service.config';
export * from './OptiaApi/mireportgroups.service.config';
export * from './OptiaApi/mireportgrouppersons.service.config';
export * from './OptiaApi/mireporttypes.service.config';
export * from './OptiaApi/mireporttypepersons.service.config';
export * from './OptiaApi/mireporttypevalidationrules.service.config';
export * from './OptiaApi/notifications.service.config';
export * from './OptiaApi/notificationpersons.service.config';
export * from './OptiaApi/notificationtemplates.service.config';
export * from './OptiaApi/optianews.service.config';
export * from './OptiaApi/optianewsdocuments.service.config';
export * from './OptiaApi/optianewspersons.service.config';
export * from './OptiaApi/permissions.service.config';
export * from './OptiaApi/people.service.config';
export * from './OptiaApi/personaddresses.service.config';
export * from './OptiaApi/persondepartments.service.config';
export * from './OptiaApi/personphonenumbers.service.config';
export * from './OptiaApi/personusergroups.service.config';
export * from './OptiaApi/phonenumbers.service.config';
export * from './OptiaApi/purchaseorders.service.config';
export * from './OptiaApi/refdataitems.service.config';
export * from './OptiaApi/refdatatypes.service.config';
export * from './OptiaApi/regexes.service.config';
export * from './OptiaApi/reportingcategories.service.config';
export * from './OptiaApi/reportinglabels.service.config';
export * from './OptiaApi/suppliers.service.config';
export * from './OptiaApi/supplieraddresses.service.config';
export * from './OptiaApi/supplieragencies.service.config';
export * from './OptiaApi/supplierbankdetails.service.config';
export * from './OptiaApi/supplierdocuments.service.config';
export * from './OptiaApi/supplierpersons.service.config';
export * from './OptiaApi/supplierworkertypes.service.config';
export * from './OptiaApi/tradingstyles.service.config';
export * from './OptiaApi/transfers.service.config';
export * from './OptiaApi/transferagencies.service.config';
export * from './OptiaApi/transfermireporttypes.service.config';
export * from './OptiaApi/transferlogs.service.config';
export * from './OptiaApi/transferrevisions.service.config';
export * from './OptiaApi/usergroups.service.config';
export * from './OptiaApi/usergrouppermissions.service.config';
export * from './OptiaApi/wfactions.service.config';
export * from './OptiaApi/wfinstances.service.config';
export * from './OptiaApi/wfoutcomes.service.config';
export * from './OptiaApi/wfprerequisites.service.config';
export * from './OptiaApi/wfprocesses.service.config';
export * from './OptiaApi/wfprocesswfschedules.service.config';
export * from './OptiaApi/wfroles.service.config';
export * from './OptiaApi/wfrules.service.config';
export * from './OptiaApi/wfschedules.service.config';
export * from './OptiaApi/wftasks.service.config';
export * from './OptiaApi/wftaskcontacttypes.service.config';
export * from './OptiaApi/wftaskgroups.service.config';
export * from './OptiaApi/wftaskpermissions.service.config';
export * from './OptiaApi/wftaskroles.service.config';
export * from './OptiaApi/wftasktabs.service.config';
export * from './OptiaApi/wftasktemplates.service.config';
export * from './OptiaApi/wftaskwfschedules.service.config';
export * from './OptiaApi/wftemplates.service.config';
export * from './OptiaApi/workers.service.config';
export * from './OptiaApi/workeraddresses.service.config';
export * from './OptiaApi/workerbankdetails.service.config';
export * from './OptiaApi/workerdocuments.service.config';
export * from './OptiaApi/workerpersons.service.config';
export * from './OptiaApi/workerphonenumbers.service.config';
export * from './OptiaApi/workertypes.service.config';
export * from './OptiaApi/workertypebankdetails.service.config';
export * from './OptiaApi/workertypedocuments.service.config';
export * from './OptiaApi/workertypepersons.service.config';
export * from './OptiaApi/workertypereportinglabels.service.config';
export * from './OptiaApi/loggedinuserdto.service.config';
export * from './OptiaApi/permissiondto.service.config';
export * from './OptiaApi/setpassworddto.service.config';
export * from './OptiaApi/wfactionoutstandingedm.service.config';
export * from './OptiaApi/actionsbypersonguid.service.config';
export * from './OptiaApi/requestsdashboardedm.service.config';
export * from './OptiaApi/dashboardrequestactionsbypersonguid.service.config';
export * from './optia2023-odata.config';
export * from './optia2023-odata.module';
//#endregion