//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OutstandingActionEdmComplexConfig } from './outstandingactionedm.complex.config';
import { WfActionEdmEntityConfig } from './wfactionedm.entity.config';
import { WfInstanceEdmEntityConfig } from './wfinstanceedm.entity.config';
import { WfOutcomeEdmEntityConfig } from './wfoutcomeedm.entity.config';
import { WfPreRequisiteEdmEntityConfig } from './wfprerequisiteedm.entity.config';
import { WfProcessEdmEntityConfig } from './wfprocessedm.entity.config';
import { WfProcessWfScheduleEdmEntityConfig } from './wfprocesswfscheduleedm.entity.config';
import { WfRoleEdmEntityConfig } from './wfroleedm.entity.config';
import { WfRuleEdmEntityConfig } from './wfruleedm.entity.config';
import { WfScheduleEdmEntityConfig } from './wfscheduleedm.entity.config';
import { WfTaskEdmEntityConfig } from './wftaskedm.entity.config';
import { WfTaskGroupEdmEntityConfig } from './wftaskgroupedm.entity.config';
import { WfTaskRoleEdmEntityConfig } from './wftaskroleedm.entity.config';
import { WfTaskTabEdmEntityConfig } from './wftasktabedm.entity.config';
import { WfTaskTemplateEdmEntityConfig } from './wftasktemplateedm.entity.config';
import { WfTaskWfScheduleEdmEntityConfig } from './wftaskwfscheduleedm.entity.config';
import { WfTemplateEdmEntityConfig } from './wftemplateedm.entity.config';
import { WfActionOutstandingEdmEntityConfig } from './wfactionoutstandingedm.entity.config';
import { RequestsDashboardEdmEntityConfig } from './requestsdashboardedm.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const WorkflowSchema = {
  namespace: 'OptiaApi.DataDomain.EFEntities.Workflow',
  enums: [],
  entities: [OutstandingActionEdmComplexConfig,
    WfActionEdmEntityConfig,
    WfInstanceEdmEntityConfig,
    WfOutcomeEdmEntityConfig,
    WfPreRequisiteEdmEntityConfig,
    WfProcessEdmEntityConfig,
    WfProcessWfScheduleEdmEntityConfig,
    WfRoleEdmEntityConfig,
    WfRuleEdmEntityConfig,
    WfScheduleEdmEntityConfig,
    WfTaskEdmEntityConfig,
    WfTaskGroupEdmEntityConfig,
    WfTaskRoleEdmEntityConfig,
    WfTaskTabEdmEntityConfig,
    WfTaskTemplateEdmEntityConfig,
    WfTaskWfScheduleEdmEntityConfig,
    WfTemplateEdmEntityConfig,
    WfActionOutstandingEdmEntityConfig,
    RequestsDashboardEdmEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion