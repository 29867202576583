import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationEdm, NotificationPersonEdm } from '@app/odata';
import { EntityKey, ODataServiceFactory } from 'angular-odata';
import { map, Observable } from 'rxjs';
import { NotificationPersonStatus } from '../lookups/enums';
import { NotificationsApiResponse } from '@app/system/models/announcements-api-response';
import { PersonAnnouncementInfo } from '@app/shared/models/person-announcement-info';

@Injectable({
  providedIn: 'root'
})
export class UserAnnouncementsService {

  constructor(private http: HttpClient, private factory: ODataServiceFactory) {
  
    }
    
    createUserAnnouncement(announcement: NotificationEdm): Observable<NotificationEdm> {
      let announcementsService = this.factory.entitySet<NotificationEdm>(
        'Notifications',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationEdm',
      );

      return announcementsService.create(announcement).pipe(
        map(prs => prs.entity as NotificationEdm)
      );
    }
  
    linkAnnouncementToPerson(announcementLink: NotificationPersonEdm): Observable<NotificationPersonEdm> {
      let announcementPersonService = this.factory.entitySet<NotificationPersonEdm>(
        'NotificationPersons',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm'
      );

      return announcementPersonService.create(announcementLink).pipe(
        map(prs => prs.entity as NotificationPersonEdm)
      );
    }
  
    getMyUnreadAnnouncements(personGuid: string, unreadOnly: boolean) {
      let announcementPersonService = this.factory.entitySet<NotificationPersonEdm>(
        'NotificationPersons',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm'
      );
  
      let announcements = announcementPersonService.entities();
      
      announcements.query((q) => q.filter({PersonGuid: personGuid }));
      if(unreadOnly) {
        // loading only announcements that weren't read
        announcements.query((q) => q.filter().push({ Status: NotificationPersonStatus.Unread }));
      }

      return announcements.fetch({ withCount: true }).pipe(
        map(res => res.entities == null ?
          [] as NotificationPersonEdm[]
          :
          res.entities as NotificationPersonEdm[]
        )
      );
    }
  
    getMyAnnouncements(personGuid: string, filter: string, sortColumn: string, sortDirection: string, pageNumber: number, pageSize: number, typeFilter?: number): Observable<NotificationsApiResponse> {
      let announcementPersonService = this.factory.entitySet<NotificationPersonEdm>(
        'NotificationPersons',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm'
      );
  
      let clearFilterStatusCode = -1; // 'Clear Filter'

      let announcements = announcementPersonService.entities();
      
      announcements.query((q) => q.filter('personGuid eq ' + personGuid));
      /*if(unreadOnly) {
        // loading only announcements that weren't read
        announcements.query((q) => q.filter().push({ Status: NotificationPersonStatus.Unread }));
      }*/

      if (filter && filter.length > 0) {
        const filterKeyword = filter.toLowerCase();
        announcements.query((q) => q.filter().push('(contains(tolower(Notification/subject), \'' + filterKeyword + '\') \
          or contains(tolower(Notification/content), \'' + filterKeyword + '\'))'));
      }

      if (typeFilter !== null && typeFilter !== undefined && typeFilter !== clearFilterStatusCode) {

        announcements.query((q) => q.filter().push('Notification/type eq ' + typeFilter));
      }

      announcements.query((q) => {
        q.expand({
            notification: { }
        })
      });

      announcements.query(q => q.skip(pageSize * pageNumber));
      announcements.query(q => q.top(pageSize));
  
      if (sortColumn && sortDirection) {
        announcements.query((q) => q.orderBy('Notification/' + sortColumn + ' ' + sortDirection));
      }
      

      return announcements.fetch({ withCount: true }).pipe(        
        map(res => res.entities == null ?
          ({
            announs: [] as NotificationPersonEdm[],
            total: 0
          } as NotificationsApiResponse)
          :
          {
            announs: res.entities,
            total: res.annots.count
          } as NotificationsApiResponse)
      );
    }
  
    getAnnouncements(personGuid: string, unreadOnly: boolean) {
      let announcementPersonService = this.factory.entitySet<NotificationPersonEdm>(
        'NotificationPersons',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm'
      );
  
      let announcements = announcementPersonService.entities();
      
      announcements.query((q) => q.filter('personGuid eq ' + personGuid));
      if(unreadOnly) {
        // loading only announcements that weren't read
        announcements.query((q) => q.filter().push({ Status: NotificationPersonStatus.Unread }));
        announcements.query((q) => q.filter().push({ DateRead: null }));
      }

      announcements.query((q) => q.orderBy('Notification/dateSent desc'));

      announcements.query((q) => {
        q.expand({
            notification: { }
        })
      });

      return announcements.fetch({ withCount: true }).pipe(
        map(res => res.entities == null ?
          [] as NotificationPersonEdm[]
          :
          res.entities as NotificationPersonEdm[]
        )
      );
    }

    
    markAnItemAsRead(announcement: PersonAnnouncementInfo): Observable<any> {
      
      let personAnnouncementToUpdate = {
        personGuid: announcement.personGuid,
        notificationGuid: announcement.notificationGuid,
        status: NotificationPersonStatus.Read,
        dateRead: new Date
      } as NotificationPersonEdm;

      let announcementPersonService = this.factory.entitySet<NotificationPersonEdm>(
        'NotificationPersons',
        'OptiaApi.DataDomain.EFEntities.Notify.NotificationPersonEdm'
      );

      let entityKey = {
        personGuid: personAnnouncementToUpdate.personGuid,
        notificationGuid: personAnnouncementToUpdate.notificationGuid
      } as EntityKey<NotificationPersonEdm>;

      return announcementPersonService.update(entityKey, personAnnouncementToUpdate);
    }
}
