//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SupplierEdm } from './supplieredm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SupplierEdmEntityConfig = {
  name: 'SupplierEdm',
  keys: [{name: 'supplierGuid'}],
  fields: {
    supplierGuid: {type: 'Edm.Guid', nullable: false},
    name: {type: 'Edm.String'},
    supplierType: {type: 'Edm.Int16', nullable: false},
    registeredNumber: {type: 'Edm.String'},
    vatNumber: {type: 'Edm.String'},
    selfBilling: {type: 'Edm.Boolean', nullable: false},
    status: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    reference: {type: 'Edm.String'},
    syncStatus: {type: 'Edm.Int16'},
    syncStatusDateTime: {type: 'Edm.DateTimeOffset'},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    supplierAddresses: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAddressEdm', collection: true, navigation: true},
    supplierAgencies: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierAgencyEdm', collection: true, navigation: true},
    supplierBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierBankDetailEdm', collection: true, navigation: true},
    supplierDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierDocumentEdm', collection: true, navigation: true},
    supplierPersons: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierPersonEdm', collection: true, navigation: true},
    supplierWorkerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierWorkerTypeEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<SupplierEdm>;
//#endregion