//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerBankDetailsService } from './customerbankdetails.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CustomerBankDetailsServiceEntitySetConfig = {
  name: 'CustomerBankDetails',
  entityType: 'OptiaApi.DataDomain.EFEntities.CustomerBankDetailEdm',
  service: CustomerBankDetailsService
} as EntitySetConfig;
//#endregion