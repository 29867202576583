import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WfTaskPermissionEdm } from './DataDomain/EFEntities/wftaskpermissionedm.entity';
import { PermissionEdm } from './DataDomain/EFEntities/Admin/permissionedm.entity';
import { WfTaskEdm } from './DataDomain/EFEntities/Workflow/wftaskedm.entity';
//#endregion

@Injectable()
export class WfTaskPermissionsService extends ODataEntitySetService<WfTaskPermissionEdm> {
  constructor(client: ODataClient) {
    super(client, 'WfTaskPermissions', 'OptiaApi.DataDomain.EFEntities.WfTaskPermissionEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public permission(key: EntityKey<WfTaskPermissionEdm>): ODataNavigationPropertyResource<PermissionEdm> { 
    return this.entity(key).navigationProperty<PermissionEdm>('permission'); 
  }
  public fetchPermission(key: EntityKey<WfTaskPermissionEdm>, options?: ODataQueryArgumentsOptions<PermissionEdm>) {
    return this.fetchNavigationProperty<PermissionEdm>(
      this.permission(key), 
      'entity', options) as Observable<ODataEntity<PermissionEdm>>;
  }
  public setPermissionEdmAsPermission(key: EntityKey<WfTaskPermissionEdm>, target: ODataEntityResource<ODataEntity<PermissionEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .set(target, {etag});
  }
  public unsetPermissionEdmAsPermission(key: EntityKey<WfTaskPermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PermissionEdm>>, etag?: string} = {}): Observable<any> {
    return this.permission(key).reference()
      .unset({etag});
  }
  public wfTask(key: EntityKey<WfTaskPermissionEdm>): ODataNavigationPropertyResource<WfTaskEdm> { 
    return this.entity(key).navigationProperty<WfTaskEdm>('wfTask'); 
  }
  public fetchWfTask(key: EntityKey<WfTaskPermissionEdm>, options?: ODataQueryArgumentsOptions<WfTaskEdm>) {
    return this.fetchNavigationProperty<WfTaskEdm>(
      this.wfTask(key), 
      'entity', options) as Observable<ODataEntity<WfTaskEdm>>;
  }
  public setWfTaskEdmAsWfTask(key: EntityKey<WfTaskPermissionEdm>, target: ODataEntityResource<ODataEntity<WfTaskEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .set(target, {etag});
  }
  public unsetWfTaskEdmAsWfTask(key: EntityKey<WfTaskPermissionEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<WfTaskEdm>>, etag?: string} = {}): Observable<any> {
    return this.wfTask(key).reference()
      .unset({etag});
  }
  //#endregion
}
