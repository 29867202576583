//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CreditRequestEdm } from './creditrequestedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CreditRequestEdmEntityConfig = {
  name: 'CreditRequestEdm',
  keys: [{name: 'creditRequestGuid'}],
  fields: {
    creditRequestGuid: {type: 'Edm.Guid', nullable: false},
    customerAgencyGuid: {type: 'Edm.Guid'},
    agencyGuid: {type: 'Edm.Guid'},
    accountGuid: {type: 'Edm.Guid'},
    creditAmount: {type: 'Edm.Decimal', scale: 'variable'},
    status: {type: 'Edm.Int16', nullable: false},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    dateAppliedFrom: {type: 'Edm.DateTimeOffset'},
    dateCommunicated: {type: 'Edm.DateTimeOffset'},
    communicatedBy: {type: 'Edm.String'},
    communicatedTo: {type: 'Edm.String'},
    shortCode: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    isExperian: {type: 'Edm.Boolean'},
    requestType: {type: 'Edm.String'},
    account: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', navigation: true, referentials: [{property: 'accountGuid', referencedProperty: 'accountGuid'}]},
    agency: {type: 'OptiaApi.DataDomain.EFEntities.Agency.AgencyEdm', navigation: true, referentials: [{property: 'agencyGuid', referencedProperty: 'agencyGuid'}]},
    customerAgency: {type: 'OptiaApi.DataDomain.EFEntities.Customer.CustomerAgencyEdm', navigation: true, referentials: [{property: 'customerAgencyGuid', referencedProperty: 'customerAgencyGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]}
  }
} as StructuredTypeConfig<CreditRequestEdm>;
//#endregion