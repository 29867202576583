//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CustomerAgencyDetailEdm } from './customeragencydetailedm.complex';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CustomerAgencyDetailEdmComplexConfig = {
  name: 'CustomerAgencyDetailEdm',
  fields: {
    customerAgencyGuid: {type: 'Edm.Guid', nullable: false},
    name: {type: 'Edm.String'},
    accountGuid: {type: 'Edm.Guid'},
    accountRef: {type: 'Edm.String'},
    notFound: {type: 'Edm.Boolean'},
    suspended: {type: 'Edm.DateTimeOffset'},
    maxExtensionReport: {type: 'Edm.DateTimeOffset'},
    cashflowReport: {type: 'Edm.DateTimeOffset'},
    insolvencyReport: {type: 'Edm.DateTimeOffset'},
    legalReport: {type: 'Edm.DateTimeOffset'},
    paymentReport: {type: 'Edm.DateTimeOffset'},
    reported: {type: 'Edm.Boolean'},
    legal: {type: 'Edm.DateTimeOffset'},
    legalName: {type: 'Edm.String'},
    contracts: {type: 'Edm.Int32'},
    customerType: {type: 'Edm.Int16'},
    level2CreditLimit: {type: 'Edm.Decimal', scale: 'variable'},
    level2Debt: {type: 'Edm.Decimal', scale: 'variable'},
    level2Exposure: {type: 'Edm.Decimal', scale: 'variable'},
    level2ExposureCap: {type: 'Edm.Decimal', scale: 'variable'},
    level3CreditLimit: {type: 'Edm.Decimal', scale: 'variable'},
    level3Debt: {type: 'Edm.Decimal', scale: 'variable'},
    level3Exposure: {type: 'Edm.Decimal', scale: 'variable'},
    level3ExposureCap: {type: 'Edm.Decimal', scale: 'variable'},
    customerAgencyStatus: {type: 'Edm.Int16', nullable: false},
    accountStatus: {type: 'Edm.Int16'},
    customerGuid: {type: 'Edm.Guid', nullable: false},
    agencyGuid: {type: 'Edm.Guid', nullable: false},
    totalSearchCount: {type: 'Edm.Int32'}
  }
} as StructuredTypeConfig<CustomerAgencyDetailEdm>;
//#endregion