//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountBankDetailEdm } from './accountbankdetailedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AccountBankDetailEdmEntityConfig = {
  name: 'AccountBankDetailEdm',
  keys: [{name: 'accountGuid'},{name: 'bankDetailsGuid'},{name: 'fromDate'}],
  fields: {
    accountGuid: {type: 'Edm.Guid'},
    bankDetailsGuid: {type: 'Edm.Guid'},
    fromDate: {type: 'Edm.DateTimeOffset', nullable: false},
    toDate: {type: 'Edm.DateTimeOffset'},
    account: {type: 'OptiaApi.DataDomain.EFEntities.AccountEdm', navigation: true, referentials: [{property: 'accountGuid', referencedProperty: 'accountGuid'}]},
    bankDetail: {type: 'OptiaApi.DataDomain.EFEntities.BankDetailEdm', navigation: true, referentials: [{property: 'bankDetailsGuid', referencedProperty: 'bankDetailsGuid'}]}
  }
} as StructuredTypeConfig<AccountBankDetailEdm>;
//#endregion