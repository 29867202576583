//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { WorkerTypeEdm } from './workertypeedm.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const WorkerTypeEdmEntityConfig = {
  name: 'WorkerTypeEdm',
  keys: [{name: 'workerTypeGuid'}],
  fields: {
    workerTypeGuid: {type: 'Edm.Guid', nullable: false},
    type: {type: 'Edm.Int16', nullable: false},
    payeDeclaration: {type: 'Edm.Int16'},
    supplierName: {type: 'Edm.String'},
    ir35Status: {type: 'Edm.Int16', nullable: false},
    utrNumber: {type: 'Edm.String'},
    createdOn: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.Guid'},
    deleted: {type: 'Edm.DateTimeOffset'},
    workerGuid: {type: 'Edm.Guid'},
    status: {type: 'Edm.Int16'},
    reference: {type: 'Edm.String'},
    startDate: {type: 'Edm.DateTimeOffset'},
    transferredToPayrollDate: {type: 'Edm.DateTimeOffset'},
    firstAssignment: {type: 'Edm.String', maxLength: -1},
    comments: {type: 'Edm.String', maxLength: -1},
    isSelfBill: {type: 'Edm.Boolean'},
    syncStatus: {type: 'Edm.Int16'},
    companyName: {type: 'Edm.String'},
    companyNumber: {type: 'Edm.String'},
    vatNumber: {type: 'Edm.String'},
    archived: {type: 'Edm.Boolean'},
    payrollFrequency: {type: 'Edm.Int32'},
    tradingStylesGuid: {type: 'Edm.Guid'},
    previousWorkerTypeGuid: {type: 'Edm.Guid'},
    syncStatusDateTime: {type: 'Edm.DateTimeOffset'},
    worker: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerEdm', navigation: true, referentials: [{property: 'workerGuid', referencedProperty: 'workerGuid'}]},
    createdByPerson: {type: 'OptiaApi.DataDomain.EFEntities.Person.PersonEdm', navigation: true, referentials: [{property: 'createdBy', referencedProperty: 'personGuid'}]},
    tradingStyle: {type: 'OptiaApi.DataDomain.EFEntities.Agency.TradingStyleEdm', navigation: true, referentials: [{property: 'tradingStylesGuid', referencedProperty: 'tradingStylesGuid'}]},
    previousWorkerType: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', navigation: true, referentials: [{property: 'previousWorkerTypeGuid', referencedProperty: 'workerTypeGuid'}]},
    previousWorkerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeEdm', collection: true, navigation: true},
    supplierWorkerTypes: {type: 'OptiaApi.DataDomain.EFEntities.Supplier.SupplierWorkerTypeEdm', collection: true, navigation: true},
    workerTypeBankDetails: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeBankDetailEdm', collection: true, navigation: true},
    workerTypeDocuments: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeDocumentEdm', collection: true, navigation: true},
    workerTypePersons: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypePersonEdm', collection: true, navigation: true},
    workerTypeReportingLabels: {type: 'OptiaApi.DataDomain.EFEntities.Worker.WorkerTypeReportingLabelEdm', collection: true, navigation: true}
  }
} as StructuredTypeConfig<WorkerTypeEdm>;
//#endregion