import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WfInstanceEdm } from "@app/odata/OptiaApi/DataDomain/EFEntities/Workflow/wfinstanceedm.entity";
import { ODataServiceFactory } from "angular-odata";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { ChangeSetEntity } from "../lookups/enums";

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {

    constructor(private http: HttpClient, private factory: ODataServiceFactory) {

    }

    getWorkflowInstanceByOwnerId(ownerGuid: string): Observable<WfInstanceEdm[]> {

        let instanceService = this.factory.entitySet<WfInstanceEdm>(
            "WfInstances",
            "OptiaAPI.DataDomain.EFEntities.WfInstanceEdm"
        );
        let instances = instanceService.entities();

        instances.query(q => q.filter().push("ownerGuid eq " + ownerGuid));


        instances.query((q) => {
            q.expand({
                wfActions: {
                    filter: { completed: null },
                    expand: {
                        wfTask: {
                            expand: {
                                wfOutcomes: {}
                            }
                        }
                    }
                }
            })
        });

        return instances.fetch().pipe(
            map(res => res.entities == null ? [] : res.entities)
        );

    }

    createWorkflowInstance(processType: number, ownerGuid: string, agencyGuid: string | undefined, entity: ChangeSetEntity| undefined): Observable<WfInstanceEdm> {

        let body = {
            ProcessType: processType,
            OwnerGuid: ownerGuid,
            AgencyGuid: agencyGuid,
            ChangeSetEntity: entity
        }

        return this.http.post(environment.API + "/WfInstances/start", body).pipe(
            map(prs => prs as WfInstanceEdm)
        );
    }

    updateWorkflowInstance(wfInstance: WfInstanceEdm) {
        let instanceService = this.factory.entitySet<WfInstanceEdm>(
            "WfInstances",
            "OptiaAPI.DataDomain.EFEntities.WfInstanceEdm"
        );

        return instanceService.update(wfInstance.wfInstanceGuid!, wfInstance)
    }

}