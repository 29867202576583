import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions,
  ODataFunctionOptions,
  ODataActionOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AccountBankDetailEdm } from './DataDomain/EFEntities/accountbankdetailedm.entity';
import { BankDetailEdm } from './DataDomain/EFEntities/bankdetailedm.entity';
import { CustomerBankDetailEdm } from './DataDomain/EFEntities/customerbankdetailedm.entity';
import { AgencyBankDetailEdm } from './DataDomain/EFEntities/Agency/agencybankdetailedm.entity';
import { PersonEdm } from './DataDomain/EFEntities/Person/personedm.entity';
import { SupplierBankDetailEdm } from './DataDomain/EFEntities/Supplier/supplierbankdetailedm.entity';
import { WorkerBankDetailEdm } from './DataDomain/EFEntities/Worker/workerbankdetailedm.entity';
import { WorkerTypeBankDetailEdm } from './DataDomain/EFEntities/Worker/workertypebankdetailedm.entity';
//#endregion

@Injectable()
export class BankDetailsService extends ODataEntitySetService<BankDetailEdm> {
  constructor(client: ODataClient) {
    super(client, 'BankDetails', 'OptiaApi.DataDomain.EFEntities.BankDetailEdm');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public accountBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<AccountBankDetailEdm> { 
    return this.entity(key).navigationProperty<AccountBankDetailEdm>('accountBankDetails'); 
  }
  public fetchAccountBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<AccountBankDetailEdm>) {
    return this.fetchNavigationProperty<AccountBankDetailEdm>(
      this.accountBankDetails(key), 
      'entities', options) as Observable<ODataEntities<AccountBankDetailEdm>>;
  }
  public addAccountBankDetailEdmToAccountBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<AccountBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.accountBankDetails(key).reference()
      .add(target);
  }
  public removeAccountBankDetailEdmFromAccountBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AccountBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.accountBankDetails(key).reference()
      .remove(target);
  }
  public agencyBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<AgencyBankDetailEdm> { 
    return this.entity(key).navigationProperty<AgencyBankDetailEdm>('agencyBankDetails'); 
  }
  public fetchAgencyBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<AgencyBankDetailEdm>) {
    return this.fetchNavigationProperty<AgencyBankDetailEdm>(
      this.agencyBankDetails(key), 
      'entities', options) as Observable<ODataEntities<AgencyBankDetailEdm>>;
  }
  public addAgencyBankDetailEdmToAgencyBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<AgencyBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.agencyBankDetails(key).reference()
      .add(target);
  }
  public removeAgencyBankDetailEdmFromAgencyBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<AgencyBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.agencyBankDetails(key).reference()
      .remove(target);
  }
  public createdByPerson(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<PersonEdm> { 
    return this.entity(key).navigationProperty<PersonEdm>('createdByPerson'); 
  }
  public fetchCreatedByPerson(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<PersonEdm>) {
    return this.fetchNavigationProperty<PersonEdm>(
      this.createdByPerson(key), 
      'entity', options) as Observable<ODataEntity<PersonEdm>>;
  }
  public setPersonEdmAsCreatedByPerson(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntity<PersonEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .set(target, {etag});
  }
  public unsetPersonEdmAsCreatedByPerson(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PersonEdm>>, etag?: string} = {}): Observable<any> {
    return this.createdByPerson(key).reference()
      .unset({etag});
  }
  public customerBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<CustomerBankDetailEdm> { 
    return this.entity(key).navigationProperty<CustomerBankDetailEdm>('customerBankDetails'); 
  }
  public fetchCustomerBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<CustomerBankDetailEdm>) {
    return this.fetchNavigationProperty<CustomerBankDetailEdm>(
      this.customerBankDetails(key), 
      'entities', options) as Observable<ODataEntities<CustomerBankDetailEdm>>;
  }
  public addCustomerBankDetailEdmToCustomerBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<CustomerBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.customerBankDetails(key).reference()
      .add(target);
  }
  public removeCustomerBankDetailEdmFromCustomerBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<CustomerBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.customerBankDetails(key).reference()
      .remove(target);
  }
  public supplierBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<SupplierBankDetailEdm> { 
    return this.entity(key).navigationProperty<SupplierBankDetailEdm>('supplierBankDetails'); 
  }
  public fetchSupplierBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<SupplierBankDetailEdm>) {
    return this.fetchNavigationProperty<SupplierBankDetailEdm>(
      this.supplierBankDetails(key), 
      'entities', options) as Observable<ODataEntities<SupplierBankDetailEdm>>;
  }
  public addSupplierBankDetailEdmToSupplierBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<SupplierBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.supplierBankDetails(key).reference()
      .add(target);
  }
  public removeSupplierBankDetailEdmFromSupplierBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SupplierBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.supplierBankDetails(key).reference()
      .remove(target);
  }
  public workerBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<WorkerBankDetailEdm> { 
    return this.entity(key).navigationProperty<WorkerBankDetailEdm>('workerBankDetails'); 
  }
  public fetchWorkerBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<WorkerBankDetailEdm>) {
    return this.fetchNavigationProperty<WorkerBankDetailEdm>(
      this.workerBankDetails(key), 
      'entities', options) as Observable<ODataEntities<WorkerBankDetailEdm>>;
  }
  public addWorkerBankDetailEdmToWorkerBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<WorkerBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerBankDetails(key).reference()
      .add(target);
  }
  public removeWorkerBankDetailEdmFromWorkerBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerBankDetails(key).reference()
      .remove(target);
  }
  public workerTypeBankDetails(key: EntityKey<BankDetailEdm>): ODataNavigationPropertyResource<WorkerTypeBankDetailEdm> { 
    return this.entity(key).navigationProperty<WorkerTypeBankDetailEdm>('workerTypeBankDetails'); 
  }
  public fetchWorkerTypeBankDetails(key: EntityKey<BankDetailEdm>, options?: ODataQueryArgumentsOptions<WorkerTypeBankDetailEdm>) {
    return this.fetchNavigationProperty<WorkerTypeBankDetailEdm>(
      this.workerTypeBankDetails(key), 
      'entities', options) as Observable<ODataEntities<WorkerTypeBankDetailEdm>>;
  }
  public addWorkerTypeBankDetailEdmToWorkerTypeBankDetails(key: EntityKey<BankDetailEdm>, target: ODataEntityResource<ODataEntities<WorkerTypeBankDetailEdm>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.workerTypeBankDetails(key).reference()
      .add(target);
  }
  public removeWorkerTypeBankDetailEdmFromWorkerTypeBankDetails(key: EntityKey<BankDetailEdm>, {target, etag}: {target?: ODataEntityResource<ODataEntities<WorkerTypeBankDetailEdm>>, etag?: string} = {}): Observable<any> {
    return this.workerTypeBankDetails(key).reference()
      .remove(target);
  }
  //#endregion
}
